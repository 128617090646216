import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { useAuth } from "../../providers/auth";

const AdminNavbar = (props) => {
  const history = useHistory();
  const { setUser } = useAuth();

  function logout() {
    var data = new Date();
    var ano = data.getFullYear();
    var _coligada = localStorage.getItem("@meuboleto-app/coligada");
    var _filial = localStorage.getItem("@meuboleto-app/filial");
    setUser({
      nome: "",
      ra: "",
      email: "",
      coligada: _coligada,
      filial: _filial,
      dtnasc: "",
      bgcolor: "",
      turma: "",
      logo: "Aluno",
      perlet: ano,
      logado: false,
    });
    localStorage.removeItem("@meuboleto/perlet");
    localStorage.removeItem("@meuboleto/logo");
    localStorage.removeItem("@meuboleto/editora");
    localStorage.removeItem("@meuboleto-app/editora");
    localStorage.removeItem("@meuboleto/email");
    localStorage.removeItem("@meuboleto/logado");
    localStorage.removeItem("@meuboleto/nome");
    localStorage.removeItem("@meuboleto/ra");
    localStorage.removeItem("@meuboleto/app");
    localStorage.removeItem("@meuboleto/dtnasc");
    localStorage.removeItem("@meuboleto/turma");
    localStorage.removeItem("@meuboleto-app/idempresaenotas");
    history.push(`/auth/login?coligada=${_coligada}&filial=${_filial}`);
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-uppercase d-none d-lg-inline-block"
            style={{ color: '#6e6e6e' }}
            to="/"
          >
            {props.brandText}
          </Link>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/theme/no-avatar.png").default}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span className="mb-0 text-sm font-weight-bold" style={{ color: '#6e6e6e' }}>
                        {localStorage.getItem("@meuboleto/nome")}
                      </span>
                      <span className="mb-0 text-sm font-weight-bold" style={{ textAlign: 'right', color: '#6e6e6e' }}>
                        RA: {localStorage.getItem("@meuboleto/ra")}
                      </span>
                    </div>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={logout}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
