import React, { useEffect, useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { lighten } from 'polished';
import routes from "routes.js";

const api = process.env.REACT_APP_HOST;

const fetchData = async (coligada, filial) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    if (coligada !== null && filial !== null) {
      var raw = JSON.stringify(
        {
          "coligada": coligada,
          "filial": filial
        }
      );
    } else {
      var raw = JSON.stringify(
        {
          "coligada": coligada,
          "filial": 1
        }
      );
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const response = await fetch(`${api}/logo`, requestOptions);
    const result = await response.json();

    if (result.record_count > 0) {
      // Identidade da Marca
      const newPrimaryColor = result.dados.bgcolor ? result.dados.bgcolor : '#1A508C';
      const newLighterPrimaryColor = lighten(0.2, newPrimaryColor);

      return {
        background: `linear-gradient(to right, ${newPrimaryColor}, ${newLighterPrimaryColor})`,
        width: '100%'
      };

      localStorage.setItem('@meuboleto/bgcolor', newPrimaryColor ? newPrimaryColor : '#1A508C');
    } else {
      // localStorage.setItem('@meuboleto/logo', imageFail);
    }

  } catch (error) {
    console.log('error', error)
  }
}

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [bgcolor, setBgcolor] = useState('');
  const [primaryColor, setPrimaryColor] = useState('#1A508C');
  const [lighterPrimaryColor, setLighterPrimaryColor] = useState(lighten(0.2, primaryColor));
  const [gradientStyle, setGradientStyle] = useState({
    // background: 'linear-gradient(to right, #1A508C, #2b87ed)',
    background: 'linear-gradient(to right, #fafafa, #f5f5f5)',
    width: '100%'
  });

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  
    const search = location.search; 
    const params = new URLSearchParams(search);
    const _coligada = params.get('coligada');
    const _filial = params.get('filial');
    localStorage.setItem("@meuboleto-app/coligada", _coligada);
  
    // fetchData(_coligada, _filial).then(style => {
    //   setGradientStyle(style);
  
    //   const { background } = style;
    //   document.body.style.background = background;
    // });
  
    return () => {
      document.body.classList.remove("bg-default");
      document.body.style.background = ''; // Limpa a cor quando o componente é desmontado
    };
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <>
            {/* <Route
              path="/login"
              component={Login}
              key="KPDLOG"
            /> */}
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          </>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div 
        className="main-content" 
        style={gradientStyle}
        ref={mainContent}
      >
        {/* <AuthNavbar /> */}
        <div 
          style={gradientStyle}
          className="py-7 py-lg-8"
        >
          
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
      {/* <AuthFooter /> */}
    </>
  );
};

export default Auth;
