import React from 'react'
import Lottie from 'lottie-react'

import animationData from '../../assets/lottiefiles/sem-dados.json'

const CardSemDados = ({ msg }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Lottie
        animationData={animationData}
        style={{
          width: 200,
          height: 200,
        }}
      />
      <p
        style={{
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '500',
          color: '#404040',
        }}
      >
        Busca de boletos
      </p>
      <p style={{ textAlign: 'center', fontSize: '13px', color: '#404040' }}>
        {msg
          ? msg
          : 'Você não possui nenhum boleto em aberto para este ano letivo'}
      </p>
    </div>
  )
}

export default CardSemDados
