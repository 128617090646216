import React, { useState, useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { lighten } from 'polished';
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";

const api = process.env.REACT_APP_HOST;

const fetchData = async (coligada, filial) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    if (coligada !== null && filial !== null) {
      var raw = JSON.stringify(
        {
          "coligada": coligada,
          "filial": filial
        }
      );
    } else {
      var raw = JSON.stringify(
        {
          "coligada": coligada,
          "filial": 1
        }
      );
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const response = await fetch(`${api}/logo`, requestOptions);
    const result = await response.json();

    if (result.record_count > 0) {
      // Identidade da Marca
      const newPrimaryColor = result.dados.bgcolor ? result.dados.bgcolor : '#1A508C';
      const newLighterPrimaryColor = lighten(0.2, newPrimaryColor);

      return {
        background: `linear-gradient(to right, ${newPrimaryColor}, ${newLighterPrimaryColor})`,
        width: '100%'
      };

      localStorage.setItem('@meuboleto/bgcolor', result.dados.bgcolor);
      // setPrimaryColor(newPrimaryColor);
      // setLighterPrimaryColor(newLighterPrimaryColor);
    } else {
      // localStorage.setItem('@meuboleto/logo', imageFail);
    }

  } catch (error) {
    console.log('error', error)
  }
}

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [primaryColor, setPrimaryColor] = useState('#fafafa');
  const [lighterPrimaryColor, setLighterPrimaryColor] = useState(lighten(0.2, primaryColor));
  const [gradientStyle, setGradientStyle] = useState({
    background: 'linear-gradient(to right, #fafafa, #f5f5f5)',
  });
  const newPrimaryColor = localStorage.getItem("@meuboleto/bgcolor") ? localStorage.getItem("@meuboleto/bgcolor") : '#1A508C';
  const newLighterPrimaryColor = lighten(0.2, newPrimaryColor);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    const _coligada = localStorage.getItem("@meuboleto-app/coligada");
    const _filial = localStorage.getItem("@meuboleto-app/filial");
    // fetchData(_coligada, _filial);

    // console.log('@meuboleto/bgcolor',localStorage.getItem("@meuboleto/bgcolor"));

  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div
        className="main-content"
        style={gradientStyle}
        ref={mainContent}
      >
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
      </div>
    </>
  );
};

export default Admin;
