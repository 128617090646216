import { lighten } from 'polished'
import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactGA from 'react-ga4'
import 'react-loading-skeleton/dist/skeleton.css'
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap'
import Swal from 'sweetalert2'

const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID
ReactGA.initialize(googleAnalitcsID)

//const api = process.env.REACT_APP_HOST;
const api_2 = process.env.REACT_APP_HOST_2

function BtnBoleto(props) {
  const [qtdparcelascartao, setqtdparcelascartao] = useState([])
  const [validboleto, setValidboleto] = useState('')
  const [ipte, setIpte] = useState('')
  const [isPagtoCC, setIsPagtoCC] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [copiedText, setCopiedText] = useState()
  const [tipobtn, setTipobtn] = useState('')
  const [modal3, setModal3] = useState(false)
  const abrirboleto = () => setModal3(!modal3)

  // Identidade da Marca
  const primaryColor = localStorage.getItem('@meuboleto/bgcolor')
    ? localStorage.getItem('@meuboleto/bgcolor')
    : '#1A508C'
  const lighterPrimaryColor = lighten(0.2, primaryColor)

  const gradientStyle = {
    background: `linear-gradient(to right, ${primaryColor}, ${lighterPrimaryColor})`,
    width: '100%',
  }

  const api_matriculei = process.env.REACT_APP_API_MATRICULEI

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    async function servicoContratadoCartao(codcoligada, idboleto, tipo) {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
      }

      let url = ''
      tipo === 'mensalidade'
        ? (url = `${api_matriculei}/api/Matriculas/ObterDadosParcelaMensalidade/${codcoligada}/${idboleto}`)
        : (url = `${api_matriculei}/api/Matriculas/ObterDadosParcelaMd/${codcoligada}/${idboleto}/${props.ra}`)
      await fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.meioPgto === 'CC') {
            setIsPagtoCC(true)
          } else {
            setIsPagtoCC(false)
          }
        })
        .catch((error) => {
          setIsPagtoCC(false)
        })
    }

    let qtdParcelas = ''
    async function getParcelasCartao() {
      if (props.tipoparcelamento === 'MD') {
        qtdParcelas = await fetch(
          `${api_matriculei}/api/Matriculas/ObterDadosParcelaMd/${props.codcoligada}/${props.idboleto}/${props.ra}`
        )
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText)
            }
            return response.json()
          })
          .then((result) => {
            result.ra !== undefined ? setIsVisible(true) : setIsVisible(false)
          })
          .catch((error) => {
            setIsVisible(false)
          })
      } else {
        qtdParcelas = await fetch(
          `${api_matriculei}/api/Matriculas/ObterDadosParcelaMensalidade/${props.codcoligada}/${props.idboleto}`
        )
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText)
            }
            return response.json()
          })
          .then((result) => {
            result.ra !== undefined ? setIsVisible(true) : setIsVisible(false)
          })
          .catch((error) => {
            setIsVisible(true)
          })
      }
    }

    if (props.codServico && props.codServico.startsWith('SS1')) {
      setIsVisible(true)
    } else {
      getParcelasCartao()
      servicoContratadoCartao(props.codcoligada, props.idboleto, props.tipo)
    }
  }, [props.idboleto])

  return (
    <>
      {/* MODAL BOLETO */}
      <Modal centered isOpen={modal3} toggle={abrirboleto}>
        <ModalHeader toggle={abrirboleto}>
          <h3>Boleto Bancário </h3>
        </ModalHeader>
        <ModalBody>
          <span>Código de barras</span>
          <div style={{ marginTop: '10px' }} className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder=""
              value={ipte}
              disabled
            />
            <div className="input-group-append">
              <CopyToClipboard
                text={ipte}
                onCopy={() => {
                  setCopiedText(ipte)
                  ReactGA.event({
                    category: 'BtnBoleto',
                    action: 'LinhaDigBoleto',
                    label: `Linha digitável: ${ipte}`,
                  })
                }}
              >
                <button
                  style={{ backgroundColor: primaryColor, color: '#fff' }}
                  className={`btn btn-${primaryColor} `}
                  name="ipte"
                  id="ipte"
                  type="button"
                >
                  <i className="far fa-copy"></i>
                </button>
              </CopyToClipboard>
              <UncontrolledTooltip
                delay={0}
                trigger="hover focus"
                target="ipte"
              >
                {copiedText === ipte
                  ? 'Linha digitável copiada!'
                  : 'Copiar linha digitável'}
              </UncontrolledTooltip>
            </div>
          </div>
          <a
            style={{ backgroundColor: primaryColor, color: '#fff' }}
            className={`btn btn-${primaryColor} btn-block`}
            target="_blank"
            href={`${api_2}/visualizar?tipo=${tipobtn}&coligada=${props.codcoligada}&idboleto=${validboleto}&ra=${props.ra}`}
            onClick={() =>
              ReactGA.event({
                category: 'BtnBoleto',
                action: 'ImprimirBoleto',
                label: `${api_2}/visualizar?tipo=${tipobtn}&coligada=${props.codcoligada}&idboleto=${validboleto}&ra=${props.ra}`,
              })
            }
            rel="noreferrer"
          >
            IMPRIMIR
          </a>
        </ModalBody>
      </Modal>

      {isPagtoCC === false ? (
        isVisible === true ? (
          <button
            variant="success"
            class={`btn-block btn mr-1`}
            style={{
              cursor: 'pointer',
              fontSize: '0.7rem',
              backgroundColor: primaryColor,
              color: '#fff',
              padding: '6px',
            }}
            onClick={() => {
              if (props.acao === 'solicitar') {
                Swal.fire(
                  '',
                  'Este boleto está em processo de registro junto ao banco. Você já pode imprimir ou salvar o seu boleto, porém o mesmo só poderá ser pago após o registro no banco ser concluído. Este processo pode levar até 2 horas.',
                  'warning'
                )
              }

              if (props.statusboleto === 1) {
                Swal.fire({
                  icon: 'success',
                  title: 'Boleto Remetido',
                  text: 'Seu boleto já foi remetido para o banco, em até 40 min estará disponível para pagamento.',
                })
              }
              setModal3(!modal3)
              setValidboleto(props.idboleto)
              setIpte(props.ipte)
              setTipobtn(props.tipo)
            }}
          >
            <i class="fas fa-file-import" style={{ marginRight: '5px' }}></i>
            {props.acao === 'solicitar' ? (
              <>
                <span
                  style={{
                    color: '#fff',
                    fontSize: '12px',
                  }}
                >
                  Solicitar boleto
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    color: '#fff',
                    fontSize: '12px',
                  }}
                >
                  Boleto
                </span>
              </>
            )}
          </button>
        ) : null
      ) : (
        <button
          variant="success"
          class={`btn-block btn mr-1`}
          style={{
            cursor: 'pointer',
            fontSize: '0.7rem',
            backgroundColor: primaryColor,
            color: '#fff',
            padding: '6px',
          }}
          onClick={() => {
            if (props.acao === 'solicitar') {
              Swal.fire(
                '',
                'Este boleto está em processo de registro junto ao banco. Você já pode imprimir ou salvar o seu boleto, porém o mesmo só poderá ser pago após o registro no banco ser concluído. Este processo pode levar até 2 horas.',
                'warning'
              )
            }

            if (props.statusboleto === 1) {
              Swal.fire({
                icon: 'success',
                title: 'Boleto Remetido',
                text: 'Seu boleto já foi remetido para o banco, em até 40 min estará disponível para pagamento.',
              })
            }
            setModal3(!modal3)
            setValidboleto(props.idboleto)
            setIpte(props.ipte)
            setTipobtn(props.tipo)
          }}
        >
          <i class="fas fa-file-alt"></i> Boleto
        </button>
      )}
    </>
  )
}

export default BtnBoleto
