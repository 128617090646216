import React, { useState, useEffect, useMemo } from 'react'
import BtnBoleto from './BtnBoleto'
import BtnCartao from './BtnCartao'
import BtnPix from './BtnPix'
import BtnNfse from './BtnNfse'
import SolicitarBoletos from './BtnSolicitarBoleto'
import { lighten } from 'polished'

const api = process.env.REACT_APP_HOST
const api_matriculei = process.env.REACT_APP_API_MATRICULEI

const TableButtons = React.memo(
  ({
    itemBoleto,
    user,
    exibirBoleto = true,
    exibirCartao = true,
    exibirNfse = true,
  }) => {
    const [nmservicos, setNmServicos] = useState('')
    const [contratouServico, setContratouServico] = useState(false)
    const primaryColor = localStorage.getItem('@meuboleto/bgcolor') || '#1A508C'
    const lighterPrimaryColor = useMemo(
      () => lighten(0.2, primaryColor),
      [primaryColor]
    )
    const gradientStyle = useMemo(
      () => ({
        background: `linear-gradient(to right, ${primaryColor}, ${lighterPrimaryColor})`,
        width: '100%',
      }),
      [primaryColor, lighterPrimaryColor]
    )

    const temAnuidade = (servico) => {
      if (servico.length > 0) {
        const servicoNomeMin = servico.toLowerCase()
        return servicoNomeMin.includes('anuidade')
      }
      return false
    }

    const servicoContratadoCartao = async (codcoligada, idboleto) => {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
      }

      let meioPgto = await fetch(
        `${api_matriculei}/api/Matriculas/ObterDadosParcelaMensalidade/${codcoligada}/${idboleto}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.meioPgto === 'CC') {
            return true
          } else {
            return false
          }
        })
        .catch((error) => {
          console.log('error', error)
          return false
        })
      return meioPgto
    }

    useEffect(() => {
      const fetchServico = async () => {
        try {
          const myHeaders = new Headers()
          myHeaders.append(
            'Cookie',
            'ci_session=qa9suqr0d45r0d0nbb5fjc2etje963st'
          )

          const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          }
          const response = await fetch(
            `${api}/listaservicosboleto?ra=${itemBoleto.RA}&coligada=${itemBoleto.CODCOLIGADA}&idlan=${itemBoleto.IDLAN}`,
            requestOptions
          )
          const result = await response.json()
          setNmServicos(result.dados[0].NOME)
        } catch (error) {
          console.log('error', error)
        }
      }

      fetchServico()

      servicoContratadoCartao(
        itemBoleto.CODCOLIGADA,
        itemBoleto.IDBOLETO ? itemBoleto.IDBOLETO : 0
      )
        .then((formaPagto) => {
          if (formaPagto === true) {
            setContratouServico(true)
          } else {
            setContratouServico(false)
          }
        })
        .catch((error) => {
          setContratouServico(false)
        })
    }, [itemBoleto])

    return (
      <div className="d-flex align-items-center justify-content-center">
        {itemBoleto.BTN_STATUS === 'Imprimir' && (
          <>
            {!contratouServico && exibirBoleto && (
              <BtnBoleto
                ra={itemBoleto.RA}
                codcoligada={itemBoleto.CODCOLIGADA}
                codfilial={itemBoleto.CODFILIAL}
                codperlet={itemBoleto.CODPERLET}
                codturma={itemBoleto.CODTURMA}
                idboleto={itemBoleto.IDBOLETO}
                statusboleto={itemBoleto.statucnab}
                ipte={itemBoleto.IPTE}
                codbarras={itemBoleto.CODIGOBARRAS}
                tipo="mensalidade"
                codServico={itemBoleto.CODSERVICO}
              />
            )}
            {!temAnuidade(nmservicos) && exibirCartao && (
              <BtnCartao
                ra={itemBoleto.RA}
                codcoligada={itemBoleto.CODCOLIGADA}
                codfilial={itemBoleto.CODFILIAL}
                codperlet={itemBoleto.CODPERLET}
                codturma={itemBoleto.CODTURMA}
                idboleto={itemBoleto.IDBOLETO}
                tipoparcelamento="Mensalidade"
              />
            )}
            <BtnPix
              ra={itemBoleto.RA}
              codcoligada={itemBoleto.CODCOLIGADA}
              codfilial={itemBoleto.CODFILIAL}
              codperlet={itemBoleto.CODPERLET}
              codturma={itemBoleto.CODTURMA}
              idboleto={itemBoleto.IDBOLETO}
              tipoparcelamento="Mensalidade"
            />
          </>
        )}

        {itemBoleto.BTN_STATUS === 'Pago' && (
          <>
            {user.idempresaenotas !== 'NAO_INTEGRADA' ? (
              exibirNfse && (
                <BtnNfse
                  codcoligada={itemBoleto.CODCOLIGADA}
                  codfilial={itemBoleto.CODFILIAL}
                  idempresa={user.idempresaenotas}
                  idboleto={itemBoleto.IDBOLETO}
                  idLan={itemBoleto.IDLAN}
                />
              )
            ) : (
              <a
                style={{ backgroundColor: primaryColor, color: '#fff' }}
                className={`btn btn-${primaryColor} btn-block`}
              >
                PAGO
              </a>
            )}
          </>
        )}

        {itemBoleto.BTN_STATUS === 'Solicitar' && (
          <>
            <SolicitarBoletos
              codcoligada={itemBoleto.CODCOLIGADA}
              statucnab={itemBoleto.CNABSTATUS}
              idboleto={itemBoleto.IDBOLETO}
              idlan={itemBoleto.IDLAN}
            />
            <br />
            {!temAnuidade(nmservicos) && exibirCartao && (
              <BtnCartao
                codcoligada={itemBoleto.CODCOLIGADA}
                idboleto={itemBoleto.IDBOLETO}
                tipoparcelamento="Mensalidade"
              />
            )}
          </>
        )}

        {itemBoleto.BTN_STATUS === 'BoletonaoRegistrado' && (
          <>
            {itemBoleto.NUMBANCO === '001' ? (
              <SolicitarBoletos
                codcoligada={itemBoleto.CODCOLIGADA}
                statucnab={itemBoleto.CNABSTATUS}
                idboleto={itemBoleto.IDBOLETO}
                idlan={itemBoleto.IDLAN}
              />
            ) : (
              <>
                {!contratouServico && exibirBoleto && (
                  <BtnBoleto
                    ra={itemBoleto.RA}
                    codcoligada={itemBoleto.CODCOLIGADA}
                    idboleto={itemBoleto.IDBOLETO}
                    statusboleto={itemBoleto.statucnab}
                    ipte={itemBoleto.IPTE}
                    codbarras={itemBoleto.CODIGOBARRAS}
                    tipo="mensalidade"
                    acao="solicitar"
                    codServico={itemBoleto.CODSERVICO}
                  />
                )}
              </>
            )}
            <br />
            {!temAnuidade(nmservicos) && exibirCartao && (
              <BtnCartao
                codcoligada={itemBoleto.CODCOLIGADA}
                idboleto={itemBoleto.IDBOLETO}
                tipoparcelamento="Mensalidade"
              />
            )}
          </>
        )}
      </div>
    )
  }
)

export default TableButtons
