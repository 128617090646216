import React, { useEffect, useState } from 'react';
import { UncontrolledTooltip } from "reactstrap";

const api = process.env.REACT_APP_HOST;

function Diversos(props) {
  const [servicos, setServicos] = useState([]);
    const [nmservicos, setNmServicos] = useState("");
    const [listaServicosCarregada, setListaServicosCarregada] = useState(false);
  
    useEffect(() => {
      if (!listaServicosCarregada) {
        const myHeaders = new Headers();
        myHeaders.append(
          "Cookie",
          "ci_session=qa9suqr0d45r0d0nbb5fjc2etje963st"
        );
  
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
  
        fetch(
          `${api}/listaservicosboleto?ra=${localStorage.getItem(
            "@meuboleto/ra"
          )}&coligada=${localStorage.getItem(
            "@meuboleto-app/coligada"
          )}&idboleto=${props.idboleto}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setNmServicos(result.dados[0].NOME);
            setServicos(result.dados);
            setListaServicosCarregada(true);
            // abrir();
          })
          .catch((error) => console.log("error", error));
      }
    }, [props.idboleto, listaServicosCarregada]);
  
    return (
      <>
        <span>
          {servicos.length > 0 ? (
            servicos.map((servico, index) => (
              <span key={index} className="mb-0 text-sm">{servico.NOME}<br /></span>
            ))
          ) : (
            <span style={{ color: "#000" }}>
              Não existem serviços.
            </span>
          )}
        </span>
      </>
    );
}

export default Diversos;