import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
//import "./fonts.scss"; 

import Manutencao from "./views/examples/NotFound.js";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { AuthProvider } from "./providers/auth";

const isAuthenticated = localStorage.getItem("@meuboleto/logado") === 'true';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/manutencao" component={Manutencao} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route exact path="/">
            {isAuthenticated ? <Redirect to="/admin/index" /> : <Redirect to="/auth/login?coligada=12" />}
          </Route>
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
