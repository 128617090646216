import { useEffect, useState } from 'react'
import {
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Button,
} from 'reactstrap'
import Swal from 'sweetalert2'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import 'react-loading-skeleton/dist/skeleton.css'
import { useAuth } from '../../providers/auth'
import ReactGA from 'react-ga4'
import { padding, lighten } from 'polished'

const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID
ReactGA.initialize(googleAnalitcsID)

// Identidade da Marca
const primaryColor = localStorage.getItem('@meuboleto/bgcolor')
  ? localStorage.getItem('@meuboleto/bgcolor')
  : '#1A508C'
const lighterPrimaryColor = lighten(0.2, primaryColor)

const gradientStyle = {
  background: `linear-gradient(to right, ${primaryColor}, ${lighterPrimaryColor})`,
  width: '100%',
}

const api = process.env.REACT_APP_HOST
const api_2 = process.env.REACT_APP_HOST_2
const api_matriculei = process.env.REACT_APP_API_MATRICULEI

const BtnCartao = (props) => {
  const { user, setUser } = useAuth()
  const [isloadqtdparc, setloadqtdparc] = useState(false)
  const [qtdparcelascartao, setqtdparcelascartao] = useState([])
  const [modal, setModal] = useState(false)
  const [validboleto, setValidboleto] = useState('')
  const [ra, setRa] = useState('')
  const [codColigada, setCodcoligada] = useState('')
  const [codFilial, setCodFilial] = useState('')
  const [codperlet, setcodperlet] = useState(2021)
  const [tipoparcelamento, settipoparcelamento] = useState('')
  const [qtdparcelas, setqtdparcelas] = useState('')

  function toggle() {
    setModal(!modal)
  }

  function onChangeParcelas(ev) {
    setqtdparcelas(ev.target.value)
  }

  async function getCodEditora(coligada, filial, ano) {
    let editora = await fetch(
      `${api}/geteditora?coligada=${coligada}&ano=${ano}&filial=${filial}`
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.record_count > 0) {
          return result.dados[0].CODEDITORA
        } else {
          return 16
        }
      })
      .catch((error) => console.log('error', error))

    return editora
  }

  async function onSubmitParcelas(ev) {
    ev.preventDefault()
    //console.log(qtdparcelas);
    if (qtdparcelas > 0) {
      if (tipoparcelamento === 'MD') {
        var myHeaders = new Headers()
        myHeaders.append(
          'Cookie',
          'ci_session=498ca3b1614do5euu34864ue29edr5es'
        )

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }

        var coligada = props.codcoligada

        window.open(
          `${api_matriculei}/api/Decodificar/CriarPagamentoWebDecodificarComRedirect/${validboleto}/${props.idlan}/2/3/${coligada}/${qtdparcelas}/${qtdparcelas}`,
          '_blank'
        )
      } else {
        var myHeaders = new Headers()
        myHeaders.append(
          'Cookie',
          'ci_session=498ca3b1614do5euu34864ue29edr5es'
        )

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }

        fetch(
          `${api}/idlancamento?ra=${localStorage.getItem(
            '@meuboleto/ra'
          )}&coligada=${codColigada}&idboleto=${validboleto}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            window.open(
              `${api_matriculei}/api/Decodificar/CriarPagamentoWebDecodificarComRedirect/${validboleto}/${result.dados[0].IDLAN}/2/3/${codColigada}/${qtdparcelas}/${qtdparcelas}`,
              '_blank'
            )
          })
          .catch((error) => console.log('error', error))
      }
    } else {
      Swal.fire(
        'Quantidade de Parcelas',
        'Você deve selecionar a quantidade de parcelas antes de realizar o pagamento.',
        'warning'
      )
    }
  }

  function onClickCartao() {
    if (props.tipoparcelamento === 'MD') {
      setloadqtdparc(true)
      fetch(
        `${api_matriculei}/api/Matriculas/ObterDadosParcelaMd/${props.codcoligada}/${props.idboleto}/${props.ra}`
      )
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response.json()
        })
        .then((result) => {
          setloadqtdparc(false)
          if (result.ra !== undefined && result.meioPgto === 'CC') {
            if (props.statusAg === 'PREAGENDADO') {
              setqtdparcelascartao({
                RA: user.ra,
                QtdParcelas: 1,
                Status: false,
              })
            } else if (props.statusAg === 'CANCELADO') {
              setqtdparcelascartao({
                RA: user.ra,
                QtdParcelas: 1,
                Status: false,
              })
            } else {
              setqtdparcelascartao({
                RA: user.ra,
                QtdParcelas: result.qtdParcelas,
                Status: true,
              })
            }
          } else {
            setqtdparcelascartao({
              RA: user.ra,
              QtdParcelas: 1,
              Status: false,
            })
          }
        })
        .catch((error) => {
          setloadqtdparc(false)
          setqtdparcelascartao({
            RA: user.ra,
            QtdParcelas: 1,
            Status: false,
          })
        })
    } else {
      setloadqtdparc(true)
      fetch(
        `${api_matriculei}/api/Matriculas/ObterDadosParcelaMensalidade/${props.codcoligada}/${props.idboleto}`
      )
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response.json()
        })
        .then((result) => {
          setloadqtdparc(false)
          if (result.ra !== undefined && result.meioPgto === 'CC') {
            setqtdparcelascartao({
              RA: result.ra,
              QtdParcelas: result.qtdParcelas,
              Status: true,
            })
          } else {
            setqtdparcelascartao({
              RA: user.ra,
              QtdParcelas: 1,
              Status: false,
            })
          }
        })
        .catch((error) => {
          setloadqtdparc(false)
          setqtdparcelascartao({
            RA: user.ra,
            QtdParcelas: 1,
            Status: false,
          })
        })
    }
    setModal(!modal)
    setValidboleto(props.idboleto)
    setRa(props.ra)
    setCodcoligada(props.codcoligada)
    setCodFilial(props.codfilial)
    setcodperlet(props.codperlet)
    settipoparcelamento(props.tipoparcelamento)
  }

  return (
    <>
      {/* MODAL PARCELAMENTO */}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Pagamento via cartão</ModalHeader>
        <ModalBody>
          <Col lg="12" xl="12" style={{ color: '#fff' }}>
            <form>
              <div className="form-group">
                <label style={{ color: '#000' }}>
                  Escolha o número de parcelas:
                </label>
                <select
                  id="parcelas"
                  name="v"
                  className="form-control input-shadow"
                  onChange={onChangeParcelas}
                  required
                >
                  <option>Selecione a qtd. de parcelas</option>
                  {isloadqtdparc ? (
                    <option value={0}>Buscando...</option>
                  ) : (
                    <option value={qtdparcelascartao.QtdParcelas}>
                      {qtdparcelascartao.QtdParcelas}x
                    </option>
                  )}
                </select>
              </div>
            </form>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button
            color={primaryColor}
            style={{ backgroundColor: primaryColor, color: '#fff' }}
            onClick={onSubmitParcelas}
            disabled={isloadqtdparc ? true : false}
          >
            Pagar com Cartão
          </Button>
          <Button color="secondary" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <button
        variant="success"
        className={`btn-block btn mr-1`}
        style={{
          cursor: 'pointer',
          fontSize: '0.7rem',
          backgroundColor: primaryColor,
          color: '#fff',
          padding: '6px',
        }}
        onClick={onClickCartao}
      >
        <i class="far fa-credit-card" style={{ marginRight: '5px' }}></i> Cartão
      </button>
    </>
  )
}

export default BtnCartao
