import { useEffect, useState } from 'react'
import Servico from './Servicos'
import TableButtons from './TableButtons'
import TableButtonsMD from './TableButtonsMD'

const api = process.env.REACT_APP_HOST

const AgrupaBoletos = ({ key, itemBoleto, user }) => {
  const [expanded, setExpanded] = useState(false)
  const [dadosmensalidades, setdadosMensalidades] = useState([])
  const [isload, setload] = useState(false)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  // Função para formatar a data de vencimento
  const formatVencimento = (data) => {
    const [dia, mes, ano] = data.split('/')
    return `${ano}-${mes}-${dia}`
  }

  const getMesVencto = (vencimento) => {
    const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ]

    const [dia, mes, ano] = vencimento.split('/')
    const monthIndex = parseInt(mes, 10) - 1

    return months[monthIndex]
  }

  // Função para formatar o valor
  const formatValor = (valor) => {
    return parseFloat(valor)
      .toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace('R$', '')
  }

  // Obtendo a cor do status
  const getStatusColor = (status) => {
    const statusColors = {
      Aberto:
        new Date() > new Date(formatVencimento(itemBoleto.VENCIMENTO))
          ? '#f5365c'
          : '#ffd24d',
      Pago: '#2dce89',
    }
    return statusColors[status] || 'black'
  }

  const listarAboletosArupados = async (coligada, idboleto) => {
    setload(true)
    const myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=h6jqsvm0pc9h7rca5k49u0gesdfs3b0b')

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    try {
      const response = await fetch(
        `${api}/listamensalidadesagrupadasra?codcoligada=${coligada}&idboleto=${idboleto}`,
        requestOptions
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const result = await response.json()
      console.log('Dados do boletos agrupados', result.dados)
      setdadosMensalidades(result.dados)
      setload(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setdadosMensalidades([])
      setload(false)
    }
  }

  useEffect(() => {
    listarAboletosArupados(itemBoleto.CODCOLIGADA, itemBoleto.IDBOLETO)
  }, [itemBoleto])

  return (
    <>
      <tr key={key}>
        <td style={{ textAlign: 'center' }}>
          <button
            style={{
              borderRadius: '50%',
              border: '#9e9e9e',
              background: '#e8e8e8',
              color: expanded ? '#5e5e5e' : '#5e5e5e',
              fontWeight: expanded ? 600 : 200,
              fontSize: '16px',
              width: '20px',
              height: '20px',
              lineHeight: '18px',
            }}
            onClick={toggleExpand}
          >
            {expanded ? '-' : '+'}
          </button>
        </td>
        <td>Parcelas de Serviços - {getMesVencto(itemBoleto.VENCIMENTO)}</td>
        <td style={{ textAlign: 'center' }}>{itemBoleto.IDBOLETO}</td>
        <td style={{ textAlign: 'center' }}>{itemBoleto.VENCIMENTO}</td>
        <td style={{ textAlign: 'left', fontWeight: '600' }}>
          {formatValor(itemBoleto.VALOR)}
        </td>
        <td style={{ textAlign: 'left', fontWeight: '600' }}>
          {itemBoleto.VALORBAIXA ? formatValor(itemBoleto.VALORBAIXA) : '-'}
        </td>
        <td style={{ textAlign: 'center' }}>
          {
            <TableButtons
              itemBoleto={itemBoleto}
              user={user}
              exibirNfse={false}
            />
          }
        </td>
      </tr>
      {expanded && (
        <>
          {dadosmensalidades.map((itemMensalidade, index) => {
            return (
              <tr key={`expanded-${index}`}>
                <td
                  style={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                  }}
                ></td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Servico
                    codcoligada={itemMensalidade.CODCOLIGADA}
                    idlan={itemMensalidade.IDLAN}
                  />
                </td>
                <td
                  style={{ verticalAlign: 'middle', textAlign: 'center' }}
                ></td>
                <td
                  style={{ verticalAlign: 'middle', textAlign: 'center' }}
                ></td>

                <td
                  style={{
                    verticalAlign: 'middle',
                    textAlign: 'left',
                    fontWeight: '600',
                  }}
                >
                  {formatValor(itemMensalidade.VALOR)}
                </td>
                <td
                  style={{ verticalAlign: 'middle', textAlign: 'center' }}
                ></td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <div
                    style={{
                      margin: '0 auto',
                      textAlign: 'center',
                    }}
                  >
                    {itemMensalidade.TIPO !== 'MD' ? (
                      <TableButtons
                        itemBoleto={itemMensalidade}
                        user={user}
                        exibirBoleto={false}
                        exibirCartao={false}
                      />
                    ) : (
                      <TableButtonsMD
                        itemMaterial={itemMensalidade}
                        user={user}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )
          })}
        </>
      )}
    </>
  )
}

export default AgrupaBoletos
