import Index from "views/Index.js";
import IndexNew from "views/Indexz.js";
import Login from "views/examples/Login.js";
import Matriculei from "views/IndexMatriculei.js";
import Material from "views/examples/Material.js";
import Declaracao from "views/examples/Declaracao.js";
import DeclaracaoIR from "views/examples/DeclaracaoIR.js";

// Controle de rotas
var routes = [
  {
    path: "/index",
    name: "Financeiro",
    icon: "ni ni-hat-3",
    component: IndexNew,
    layout: "/admin",
    visible: true,
  },
  // {
  //   path: "/indexz",
  //   name: "Mensalidades / Material",
  //   icon: "ni ni-hat-3",
  //   component: Indexz,
  //   layout: "/admin",
  //   visible: true,
  // },
  {
    path: "/matriculei",
    name: "Matriculei",
    icon: "ni ni-books",
    component: Matriculei,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/material",
    name: "Material",
    icon: "ni ni-books",
    component: Material,
    layout: "/admin",
    visible: false,
  },
  // {
  //   path: "/loja",
  //   name: "Loja",
  //   icon: "ni ni-bag-17",
  //   component: Declaracao,
  //   layout: "/admin",
  //   visible: true,
  // },
  // {
  //   path: "/cameras",
  //   name: "Live Kids (Câmeras)",
  //   icon: "ni ni-laptop",
  //   component: Declaracao,
  //   layout: "/admin",
  //   visible: true,
  // },
  {
    path: "/irrf",
    name: "Declaração de IRRF",
    icon: "ni ni-paper-diploma",
    component: DeclaracaoIR,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/quitacao",
    name: "Declaração de Quitação",
    icon: "ni ni-archive-2",
    component: Declaracao,
    layout: "/admin",
    visible: false
  },
  {
    path: "/",
    name: "Declarações de Pagamento",
    icon: "ni ni-book-bookmark",
    component: DeclaracaoIR,
    layout: "/admin",
    visible: true,
    subItems: [
      { name: 'Por data de Recebimento', path: '/irrf', layout: "/admin", icon: "ni ni-archive-2", component: DeclaracaoIR },
      { name: 'Por Ano Letivo', path: '/quitacao', layout: "/admin", icon: "ni ni-books", component: Declaracao },
    ],
  },
  {
    path: "/login",
    name: "FAQ - Perguntas",
    icon: "ni ni-key-25",
    component: Login,
    layout: "/auth",
    visible: false,
  },
];

export default routes;
