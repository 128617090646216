import React from 'react';
import Lottie from 'lottie-react';

import animationData from '../../assets/lottiefiles/carregando-pgto.json';

const Carregando = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>

      <Lottie
        animationData={animationData}
        style={{
          width: 300,
          height: 300,
        }}
      />
      <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '500', color: '#404040' }}>Aguarde!</p>
      <p style={{ textAlign: 'center', fontSize: '13px', color: '#404040' }}>Estamos buscando os dados...</p>
    </div>

  );
};

export default Carregando;
