import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Alert,
} from 'reactstrap'
import { lighten } from 'polished'
import imgFail from '../../variables/images'
import ReCAPTCHA from 'react-google-recaptcha'
import { SpinnerCircular } from 'spinners-react'
import { AuthProvider, useAuth } from '../../providers/auth'
import ReactGA from 'react-ga4'

const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID
ReactGA.initialize(googleAnalitcsID)

const api = process.env.REACT_APP_HOST

const initialvalue = {
  usuario: '',
  senha: '',
}

const Login = (props) => {
  const { user, setUser } = useAuth()
  const [values, setValues] = useState(initialvalue)
  const [isloading, setIsloading] = useState(null)
  const [loginerro, setLoginErro] = useState(false)
  const [loginmsg, setLoginmsg] = useState(false)
  const [isLoadColigadas, setIsLoadColigadas] = useState(false)
  const [isLoadLogo, setIsLoadLogo] = useState(false)
  const [codturma, setcodturma] = useState('')
  const [codperlet, setcodperlet] = useState('')
  const [codcoligada, setCodColigada] = useState('')
  const [codfilial, setCodFilial] = useState('')
  const [recaptcha, setReCaptcha] = useState(null)
  const [imageSrc, setimageSrc] = useState('')
  const [imageFail] = useState(imgFail)
  const [decoded_string, setdecoded_string] = useState('')
  const [primaryColor, setPrimaryColor] = useState('#1A508C')
  const [lighterPrimaryColor, setLighterPrimaryColor] = useState(
    lighten(0.2, primaryColor)
  )
  const [gradientStyle, setGradientStyle] = useState()
  const [rscoligadas, setRscoligadas] = useState([])
  const [rscoligadasmanutencao, setRscoligadasmanutencao] = useState([])
  const history = useHistory()

  function checkImage(imageSrc, good, bad) {
    var img = new Image()
    img.onload = good
    img.onerror = bad
    img.src = imageSrc
  }

  async function getCodEditora(coligada, filial, ano) {
    let editora = await fetch(
      `${api}/geteditora?coligada=${coligada}&ano=${ano}&filial=${filial}`
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.record_count > 0) {
          return result.dados[0].CODEDITORA
        } else {
          return 16
        }
      })
      .catch((error) => console.log('error', error))

    return editora
  }

  const fetchData = async () => {
    const search = props.location.search
    const params = new URLSearchParams(search)
    try {
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')

      if (params.get('coligada') !== null && params.get('filial') !== null) {
        var raw = {
          coligada: params.get('coligada'),
          filial: params.get('filial'),
        }
      } else {
        var raw = {
          coligada: params.get('coligada'),
          filial: 1,
        }
      }

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: 'follow',
      }

      const response = await fetch(`${api}/logo`, requestOptions)
      const result = await response.json()

      if (result.record_count > 0) {
        // Identidade da Marca
        const newPrimaryColor = result.dados.bgcolor
          ? result.dados.bgcolor
          : '#1A508C'
        const newLighterPrimaryColor = lighten(0.2, newPrimaryColor)
        
        setGradientStyle({
          background: `linear-gradient(to right, ${newPrimaryColor}, ${newLighterPrimaryColor})`,
          width: '100%',
        })

        setdecoded_string(result.dados.logo)
        localStorage.setItem(
          '@meuboleto/bgcolor',
          result.dados.bgcolor ? result.dados.bgcolor : '#1A508C'
        )
        const logo = (raw.coligada == '12' && raw.filial == '1') ? '' : result.dados.logo;
        localStorage.setItem('@meuboleto/logo', logo);
        setPrimaryColor(newPrimaryColor)
        setLighterPrimaryColor(newLighterPrimaryColor)
      } else {
        setdecoded_string('')
        localStorage.setItem('@meuboleto/logo', '')
      }

      setIsLoadLogo(false)
    } catch (error) {
      console.log('error', error)
      setdecoded_string(imageFail)
    }
  }

  function loadIdentidade(coligada, filial) {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      coligada: coligada,
      filial: filial,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    fetch(`${api}/logo`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(coligada,filial);
        if (result.record_count > 0) {
          const logo = (coligada === 12 && filial === 1) ? '' : result.dados.logo;
          localStorage.setItem(
            '@meuboleto/bgcolor',
            result.dados.bgcolor ? result.dados.bgcolor : '#1A508C'
          )
          localStorage.setItem('@meuboleto/logo', logo)
        } else {
          localStorage.setItem('@meuboleto/logo', '')
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    var data = new Date()
    var ano = data.getFullYear()
    const search = props.location.search
    const params = new URLSearchParams(search)
    const auth_token = params.get('auth_token')
    const app = params.get('app')
    setCodColigada(params.get('coligada'))
    setCodFilial(params.get('filial'))

    // Repasso os dados para o Google Analitcs
    ReactGA.pageview(window.location.pathname + window.location.search)

    setIsLoadLogo(true)
    fetchData()

    // Redireciona para a página de manutenção
    setRscoligadasmanutencao([5700])

    if (auth_token !== null) {
      var myHeaders = new Headers()
      myHeaders.append('Cookie', 'ci_session=7ma2vj8c182lrc800v3fedf28s7tpvmr')

      var formdata = new FormData()
      formdata.append('auth_token', auth_token)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
      }

      fetch(`${api}/accessvalidation`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status_code == '400') {
            console.log({ erro: 'Falha no login' })
          } else {
            setIsloading(true)

            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                usuario: result.result[0].RA,
                senha: result.result[0].DTNASCLOGIN,
              }),
            }
            fetch(`${api}/login`, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                if (result.status_code == '400') {
                  setIsloading(false)
                  setLoginErro(true)
                  setLoginmsg('Falha: login ou senha inválidos!!!')
                } else {
                  setLoginErro(false)

                  ReactGA.event({
                    category: 'Login',
                    action: 'LoginMatriculei',
                    label: `Endpoint: ${api}/accessvalidation - Payload: ${formdata}`,
                  })

                  var data = new Date()
                  var ano = data.getFullYear()

                  var myHeaders = new Headers()
                  myHeaders.append('Content-Type', 'application/json')
                  myHeaders.append(
                    'Cookie',
                    'ci_session=k936k3fovb8oeh3ae9f5aa1af90kbai5'
                  )

                  var raw = JSON.stringify({
                    ra: result.dados[0].RA,
                    codperlet: ano,
                  })

                  var paramsTurmaAtual = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                  }

                  fetch(`${api}/selecionaturmaatual`, paramsTurmaAtual)
                    .then((response) => response.json())
                    .then((resturma) => {
                      if (resturma.status_code == '400') {
                        // console.log('Não encontrou turma para este período letívo.');
                        setLoginErro(true)
                        setLoginmsg(
                          'Falha: Não encotramos uma matrícula ativa em uma turma para este período letívo.'
                        )
                        setIsloading(false)
                      } else {
                        getCodEditora(
                          resturma.dados[0].CODCOLIGADA,
                          resturma.dados[0].CODFILIAL,
                          ano
                        )
                          .then((editora) => {
                            localStorage.setItem(
                              '@meuboleto-app/editora',
                              editora
                            )
                          })
                          .catch((error) => console.log('error', error))

                        var myHeaders = new Headers()
                        myHeaders.append('Content-Type', 'application/json')

                        var raw = {
                          coligada: resturma.dados[0].CODCOLIGADA,
                          filial: resturma.dados[0].CODFILIAL,
                        }

                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: JSON.stringify(raw),
                          redirect: 'follow',
                        }

                        fetch(`${api}/logo`, requestOptions)
                          .then((response) => response.json())
                          .then((result) => {
                            //console.log(result);
                            const logo = (raw.coligada === 12 && raw.filial === 1) ? '' : result.dados.logo;
                            if (result.record_count > 0) {
                              localStorage.setItem(
                                '@meuboleto/logo',
                                logo
                              )
                              setUser({
                                ...user,
                                logo: logo,
                              })
                            } else {
                              localStorage.setItem('@meuboleto/logo', '')
                              setUser({
                                ...user,
                                logo: '',
                              })
                            }
                          })
                          .catch((error) => {
                            console.log('error', error)
                          })

                        localStorage.setItem(
                          '@meuboleto/turma',
                          resturma.dados[0].CODTURMA
                        )
                        localStorage.setItem(
                          '@meuboleto/perlet',
                          resturma.dados[0].CODPERLET
                        )
                        localStorage.setItem(
                          '@meuboleto-app/coligada',
                          resturma.dados[0].CODCOLIGADA
                        )
                        localStorage.setItem(
                          '@meuboleto-app/filial',
                          resturma.dados[0].CODFILIAL
                        )
                        setcodturma(resturma.dados[0].CODTURMA)
                        setcodperlet(resturma.dados[0].CODPERLET)
                        loadIdentidade(
                          resturma.dados[0].CODCOLIGADA,
                          resturma.dados[0].CODFILIAL
                        )

                        localStorage.setItem(
                          '@meuboleto/ra',
                          result.dados[0].RA
                        )
                        localStorage.setItem(
                          '@meuboleto/nome',
                          result.dados[0].NOME
                        )
                        localStorage.setItem(
                          '@meuboleto/dtnasc',
                          result.dados[0].DTNASC
                        )
                        localStorage.setItem(
                          '@meuboleto/email',
                          result.dados[0].EMAIL
                        )
                        localStorage.setItem('@meuboleto/logado', true)
                        localStorage.setItem('@meuboleto/app', 'matriculei')

                        setUser({
                          ...user,
                          codcoligada: resturma.dados[0].CODCOLIGADA,
                          codfilial: resturma.dados[0].CODFILIAL,
                          codperlet: resturma.dados[0].CODPERLET,
                          codturma: resturma.dados[0].CODTURMA,
                          ra: result.dados[0].RA,
                          nome: result.dados[0].NOME,
                          logado: true,
                          app: 'matriculei',
                        })

                        app === 'matriculei'
                          ? localStorage.setItem('@meuboleto/app', 'matriculei')
                          : localStorage.removeItem('@meuboleto/app')

                        var coligada = resturma.dados[0].CODCOLIGADA
                        if (rscoligadasmanutencao.includes(coligada)) {
                          history.push('/manutencao')
                        } else {
                          //app === 'matriculei' ? history.push('/admin/matriculei') : history.push('/admin/index');
                          history.push('/admin/index')
                        }
                      }
                    })
                    .catch((error) => console.log('error', error))
                }
              })
          }
        })
        .catch((error) => {
          setLoginErro(true)
          setLoginmsg('Falha: Token inválido!')
          setIsloading(false)
        })
    }

    setIsLoadColigadas(true)

    var myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=liou8oqnllbi55sskahpnjsd8mg17vi0')

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(`${api}/getcoligadas`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoadColigadas(false)
        setRscoligadas(result.dados)
      })
      .catch((error) => {
        setIsLoadColigadas(false)
        console.log('error', error)
      })

    checkImage(
      `https://boletoonline.suporteinspira.com.br/assets/img/logos/${localStorage.getItem(
        '@meuboleto-app/coligada'
      )}.png`,
      function () {
        setimageSrc(
          `https://boletoonline.suporteinspira.com.br/assets/img/logos/${localStorage.getItem(
            '@meuboleto-app/coligada'
          )}.png`
        )
      },
      function () {
        setimageSrc(
          `https://boletoonline.suporteinspira.com.br/assets/img/logos/12.png`
        )
      }
    )
  }, [])

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  function onChangeColigada(ev) {
    window.location.href = `/auth/login?coligada=${ev.target.value}`
    let idx = ev.target.selectedIndex
    let dataset = ev.target.options[idx].dataset
  }

  function onChangeRe(value) {
    setReCaptcha(value)
    if (value) {
      setLoginErro(false)
      setLoginmsg('')
      setIsloading(false)
    } else {
      setLoginErro(true)
      setLoginmsg('Falha: Você deve validar o reCAPTCHA!')
      setIsloading(false)
    }
  }

  async function onSubmit(ev) {
    ev.preventDefault()
    setIsloading(true)

    if (values.usuario === '') {
      setLoginErro(true)
      setLoginmsg('Falha: Você deve informar um usuário!')
      setIsloading(false)
    } else if (values.usuario === '') {
      setLoginErro(true)
      setLoginmsg('Falha: Você deve informar a senha!')
      setIsloading(false)
    } else {
      if (recaptcha) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values),
        }
        fetch(`${api}/login`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.status_code == '400') {
              setIsloading(false)
              setLoginErro(true)
              setLoginmsg('Falha: login ou senha inválidos!!!')
            } else {
              setLoginErro(false)

              ReactGA.event({
                category: 'Login',
                action: 'Login',
                label: `Endpoint: ${api}/login - Payload: ${JSON.stringify(
                  values
                )}`,
              })

              var data = new Date()
              var ano = data.getFullYear()

              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'Cookie',
                'ci_session=k936k3fovb8oeh3ae9f5aa1af90kbai5'
              )

              var raw = JSON.stringify({
                ra: result.dados[0].RA,
                codperlet: ano,
              })

              var paramsTurmaAtual = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
              }

              fetch(`${api}/selecionaturmaatual`, paramsTurmaAtual)
                .then((response) => response.json())
                .then((resturma) => {
                  if (resturma.status_code == '400') {
                    // console.log('Não encontrou turma para este período letívo.');
                    setLoginErro(true)
                    setLoginmsg(
                      'Falha: Não encontramos uma matrícula ativa em uma turma para este período letívo.'
                    )
                    setIsloading(false)
                  } else {
                    getCodEditora(
                      resturma.dados[0].CODCOLIGADA,
                      resturma.dados[0].CODFILIAL,
                      ano
                    )
                      .then((editora) => {
                        localStorage.setItem('@meuboleto-app/editora', editora)
                        setUser({
                          ...user,
                          codeditora: editora,
                        })
                      })
                      .catch((error) => console.log('error', error))

                    loadIdentidade(
                      resturma.dados[0].CODCOLIGADA,
                      resturma.dados[0].CODFILIAL
                    )

                    localStorage.setItem(
                      '@meuboleto/turma',
                      resturma.dados[0].CODTURMA
                    )
                    localStorage.setItem(
                      '@meuboleto/perlet',
                      resturma.dados[0].CODPERLET
                    )
                    localStorage.setItem(
                      '@meuboleto-app/coligada',
                      resturma.dados[0].CODCOLIGADA
                    )
                    localStorage.setItem(
                      '@meuboleto-app/filial',
                      resturma.dados[0].CODFILIAL
                    )
                    setcodturma(resturma.dados[0].CODTURMA)
                    setcodperlet(resturma.dados[0].CODPERLET)

                    localStorage.setItem('@meuboleto/ra', result.dados[0].RA)
                    localStorage.setItem(
                      '@meuboleto/nome',
                      result.dados[0].NOME
                    )
                    localStorage.setItem(
                      '@meuboleto/dtnasc',
                      result.dados[0].DTNASC
                    )
                    localStorage.setItem(
                      '@meuboleto/email',
                      result.dados[0].EMAIL
                    )
                    localStorage.setItem('@meuboleto/logado', true)

                    setUser({
                      ...user,
                      codcoligada: resturma.dados[0].CODCOLIGADA,
                      codfilial: resturma.dados[0].CODFILIAL,
                      codperlet: resturma.dados[0].CODPERLET,
                      codturma: resturma.dados[0].CODTURMA,
                      ra: result.dados[0].RA,
                      nome: result.dados[0].NOME,
                      logado: true,
                    })

                    var coligada = resturma.dados[0].CODCOLIGADA
                    if (rscoligadasmanutencao.includes(coligada)) {
                      history.push('/manutencao')
                    } else {
                      history.push('/admin/index')
                    }
                  }
                })
                .catch((error) => console.log('error', error))
            }
          })
      } else {
        setLoginErro(true)
        setLoginmsg('Falha: Você deve validar o reCAPTCHA!')
        setIsloading(false)
      }
    }
  }

  
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              {isLoadLogo ? (
                <SpinnerCircular
                  color="rgba(255, 255, 255, 1)"
                  secondaryColor="rgba(0, 0, 0, 0.44)"
                  size={20}
                />
              ) : (
                <img
                  style={{
                    maxWidth: '200px',
                  }}
                  src={
                    localStorage.getItem('@meuboleto/logo') === '' ? 
                      '' : `data:image/png;base64,${decoded_string}` 
                  }
                />
              )}
            </div>
            <Form onSubmit={onSubmit} role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="RA do Aluno"
                    type="text"
                    name="usuario"
                    onChange={onChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Data de Nascimento"
                    type="date"
                    name="senha"
                    onChange={onChange}
                  />
                </InputGroup>
              </FormGroup>
              <ReCAPTCHA
                sitekey="6LdxWsspAAAAAJYeoayFHeJraZmUgbErWmhpU3Nr"
                onChange={onChangeRe}
              />

              {loginerro ? (
                <Alert color="danger" className="my-4">
                  {loginmsg}
                </Alert>
              ) : (
                ''
              )}

              <div className="text-center">
                <Button
                  className="my-4"
                  style={{
                    width: '100%',
                    backgroundColor: primaryColor,
                    color: '#fff',
                  }}
                  color={primaryColor}
                  type="submit"
                >
                  {isloading ? (
                    <SpinnerCircular
                      color="rgba(255, 255, 255, 1)"
                      secondaryColor="rgba(0, 0, 0, 0.44)"
                      size={20}
                    />
                  ) : (
                    'Entrar'
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default Login
