/*eslint-disable*/
import { useState, useEffect } from 'react'
import { NavLink as NavLinkRRD, Link } from 'react-router-dom'
import Submenu from './Submenu'

// nodejs library to set properties for components
import { PropTypes } from 'prop-types'
import { useAuth } from '../../providers/auth'

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from 'reactstrap'

var ps
const basic_url = process.env.REACT_APP_BASIC_URL

const Sidebar = (props) => {
  const { user, setUser } = useAuth()
  const [collapseOpen, setCollapseOpen] = useState()
  const [context, setContext] = useState([])

  useEffect(() => {
    setContext(user)
  }, [])

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data)
  }
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false)
  }

  // creates the links that appear in the left menu / Sidebar
  // const createLinks = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.visible !== false) {
  //       return (
  //         <NavItem key={key}>
  //           <NavLink
  //             to={prop.layout + prop.path}
  //             tag={NavLinkRRD}
  //             onClick={closeCollapse}
  //             activeClassName="active"
  //           >
  //             <i className={prop.icon} />
  //             {prop.name}
  //           </NavLink>
  //         </NavItem>
  //       );
  //     }
  //   });
  // };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.visible !== false) {
        if (prop.subItems) {
          return (
            <Submenu
              key={key}
              title={prop.name}
              icon={prop.icon}
              subItems={prop.subItems}
            />
          )
        } else {
          return (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          )
        }
      }
    })
  }

  const { bgColor, routes, logo } = props
  let navbarBrandProps
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    }
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    }
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand={localStorage.getItem('@meuboleto/app') ? '' : 'md'}
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}

        <NavbarBrand className="pt-0" {...navbarBrandProps}>
          <img
            className="navbar-brand-img"
            src={`data:image/png;base64,${localStorage.getItem(
              '@meuboleto/logo'
            )}`}
          />
        </NavbarBrand>
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={
                      require('../../assets/img/theme/no-avatar.png').default
                    }
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                <i className="ni ni-user-run" />
                <span>Sair</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img
                        className="navbar-brand-img"
                        src={`data:image/png;base64,${localStorage.getItem(
                          '@meuboleto/logo'
                        )}`}
                      />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img
                        alt={logo.imgAlt}
                        src={
                          localStorage.getItem('@meuboleto-app/coligada')
                            ? require(`../../assets/img/logos/${localStorage.getItem(
                                '@meuboleto-app/coligada'
                              )}.png`).default
                            : require(`../../assets/img/logos/12.png`).default
                        }
                      />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <div className="mt-4 mb-3 d-md-none">
            Aluno(a): {localStorage.getItem('@meuboleto/nome')}
          </div>
          <div className="mt-4 mb-3 d-md-none">
            RA: {localStorage.getItem('@meuboleto/ra')}
          </div>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

Sidebar.defaultProps = {
  routes: [{}],
}

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
}

export default Sidebar
