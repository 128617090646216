import React, { useState } from 'react';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { NavItem, NavLink, Collapse, ListGroup, ListGroupItem } from 'reactstrap';

const Submenu = ({ icon, title, subItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <NavItem>
        <NavLink to="#" onClick={toggle} style={{ cursor: 'pointer' }}>
          <i className={icon} />
          {title}
        </NavLink>
        <Collapse isOpen={isOpen}>
          <ListGroup>
            {subItems.map((item, index) => (
              <ListGroupItem key={index}>
                <NavLink
                  to={item.layout + item.path}
                  tag={NavLinkRRD}
                >
                  <i className={item.icon} />
                  {item.name}
                </NavLink>
              </ListGroupItem>
            ))}
            {/* <ListGroupItem key="irrf">
              <Link
                to='/admin/irrf'
                // tag={NavLinkRRD}
              >
                <i className="ni ni-archive-2" />
                Por data de Recebimento
              </Link>
            </ListGroupItem>
            <ListGroupItem key="quitacao">
              <Link
                to='/admin/quitacao'
                // tag={NavLinkRRD}
              >
                <i className="ni ni-books" />
                Por Ano Letivo
              </Link>
            </ListGroupItem> */}
          </ListGroup>
        </Collapse>
      </NavItem>
    </>
  );
};

export default Submenu;
