import { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { QrCode } from "react-qrcode-pretty";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import atencao from '../../assets/img/icons/warning.png';

import ReactGA from "react-ga4";

const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID; 
ReactGA.initialize(googleAnalitcsID);

const api = process.env.REACT_APP_HOST;
const userDec = process.env.REACT_APP_USER_API;

function BtnPix(props) {
  const [copiedText, setCopiedText] = useState();
  const [pixline, setpixline] = useState("");
  const [dadospix, setdadospix] = useState([]);
  const [fullPIX, setFullPIX] = useState("");
  const [isloadingpix, setisloadingpix] = useState(false);
  const [qrcodepix, setqrcodepix] = useState(
    <Skeleton height={256} width={256} />
  );

  const [modalpix, setModalpix] = useState(false);
  const abrirpix = () => setModalpix(!modalpix);

  useEffect(() => {
    // Repasso os dados para o Google Analitcs
    ReactGA.pageview(window.location.pathname + window.location.search);

    async function getPix(idboleto, codcoligada) {
      const username = userDec;

      var myHeaders = new Headers();

      var raw = JSON.stringify({
        "usuario": username,
        "idboleto": idboleto,
        "coligada": codcoligada
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const hash = await
        fetch(`${api}/pixdecodificar`, requestOptions)
          .then(response => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
          })
          .then(result => setpixline(result.ConsultarQRCodeBoletoResult.Codigo_QRCode))
          .catch(error => setpixline(""));
    }

    getPix(props.idboleto, props.codcoligada);
  }, [props.idboleto]);

  return (
    <>
      {/* MODAL PIX */}

      <Modal centered isOpen={modalpix} toggle={abrirpix}>
        <ModalHeader toggle={abrirpix}>
          <h3>Pagamento via Pix </h3>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
            className="input-group mb-3"
          >
            <p>{isloadingpix ? <Skeleton height={256} width={256} /> : dadospix.chavepix !== null ? qrcodepix : (<><img src={atencao} style={{ marginRight: '10px', marginBottom: '10px' }} /><span className="modal-text">Chave Pix não cadastrada!</span></>)}</p>

          </div>
          {isloadingpix ? null : dadospix.chavepix !== null ? (
            <>
              <span className="modal-text">Pix Copia e Cola</span>
              <div style={{ marginTop: "10px" }} className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={fullPIX}
                  disabled
                />
                <div className="input-group-append">
                  <CopyToClipboard
                    text={fullPIX}
                    onCopy={() => {
                      setCopiedText(fullPIX)
                      ReactGA.event({
                        category: 'BtnPix',
                        action: 'PixCopiaCola',
                        label: `Endpoint: ${api}/pixqrcode?idboleto=${props.idboleto}&coligada=${props.codcoligada} - Pixline: ${pixline}`,
                      });
                    }}
                  >
                    <button
                      className={`btn btn-${localStorage.getItem(
                        "@meuboleto-app/bgcolor"
                      )} btn-block`}
                      name="pix'"
                      id="pix'"
                      type="button"
                    >
                      <i className="far fa-copy"></i>
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              <span className="modal-text">
                <b>{dadospix.servico}</b>
              </span>
              <br />
              <span className="modal-text">
                Valor: <b>{dadospix.valor}</b>
              </span>
              <br />
              <span className="modal-text">Vencimento: {dadospix.dtvencimento}</span>
            </>
          ) : null}
        </ModalBody>
      </Modal>

      {/* FIM DO MODAL PIX */}

      {pixline ? (
        <>
          <a
            className={`btn btn-${localStorage.getItem(
              "@meuboleto-app/bgcolor"
            )} btn-block`}
            onClick={() => {
              if (props.statusboleto === 1) {
                Swal.fire({
                  icon: "success",
                  title: "Boleto Remetido",
                  text: "Seu boleto já foi remetido para o banco, em até 40 min estará disponível para pagamento.",
                });
              }

              var myHeaders = new Headers();
              myHeaders.append(
                "Cookie",
                "ci_session=d96bg1ivot3gsrpvb4789nq9hmo8she2"
              );

              var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
                timeout: 1000
              };

              setisloadingpix(true);

              fetch(
                `${api}/pixqrcode?idboleto=${props.idboleto
                }&coligada=${props.codcoligada}`,
                requestOptions
              )
                .then((response) => response.json())
                .then((result) => {
                  setdadospix(result.dados);
                  setFullPIX(pixline)
                  setqrcodepix(<QrCode
                    value={pixline}
                    variant={{
                      eyes: 'gravity',
                      body: 'fluid'
                    }}
                    color={{
                      eyes: '#223344',
                      body: '#3a424a'
                    }}
                    resize={256}
                    padding={10}
                    margin={20}
                    bgColor="#ebf2fa"
                    bgRounded
                    divider
                  />)
                  setisloadingpix(false);
                  ReactGA.event({
                    category: 'BtnPix',
                    action: 'Pix',
                    label: `Endpoint: ${api}/pixqrcode?idboleto=${props.idboleto}&coligada=${props.codcoligada} - Pixline: ${pixline}`,
                  });
                })
                .catch((error) => {
                  console.log("error", error)
                  setqrcodepix([])
                  setisloadingpix(false)
                });

              setModalpix(!modalpix);
            }}
          >
            PIX
          </a>
        </>
      ) : null}
    </>
  );
}

export default BtnPix;