import React from 'react';
import logoIDAAM from '../../assets/img/brand/logo-b.png';
import logo from '../../assets/img/brand/default-w.png';
import '../../assets/css/manutencao-styles.css';

const NotFound = () => (
  <div className='container'>
    <div id="clouds">
      <div class="cloud x1"></div>
      <div class="cloud x1_5"></div>
      <div class="cloud x2"></div>
      {/* <div class="cloud x3"></div> */}
      <div class="cloud x4"></div>
      {/* <div class="cloud x5"></div> */}
    </div>
    <div className='c'>
      <div className='_404'>
        <img 
          style={{
            width: '203px'
          }}
          src={logo} 
          alt="" 
        />
      </div>
      <hr className='hrmanu'/>
        <div className='_1'>MANUTENÇÃO</div>
        <div className='_2'>NO SISTEMA</div>
        <div className='texto'>
          <p>
          Prezados Pais e Responsáveis,<br/>
          Devido à necessidade de manutenção do novo sistema financeiro, suspenderemos por um breve período o acesso ao portal “Meu Boleto” e ao setor financeiro localizado na Unidade. Essa suspensão temporária terá como objetivo ajustar todas as observações apresentadas pelos responsáveis nesse início do ano letivo.<br/>
          </p>
        </div>
    </div>
  </div>
);

export default NotFound;