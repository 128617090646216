import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Button } from 'reactstrap'
import moment from 'moment'
import { SpinnerCircular } from 'spinners-react'
import 'react-loading-skeleton/dist/skeleton.css'
import ReactGA from 'react-ga4'
import { lighten } from 'polished'
import { useAuth } from '../../providers/auth'

const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID
ReactGA.initialize(googleAnalitcsID)

const api = process.env.REACT_APP_HOST

function BtnNfse(props) {
  const { user, setUser } = useAuth()
  const [isload, setIsload] = useState(null)
  const [isVisible, setIsVisible] = useState(null)

  const primaryColor = localStorage.getItem('@meuboleto/bgcolor')
    ? localStorage.getItem('@meuboleto/bgcolor')
    : '#1A508C'
  const lighterPrimaryColor = lighten(0.2, primaryColor)

  useEffect(() => {
    getNotaBoleto(props.idboleto)
      .then((data) => {
        if (data !== false) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      })
      .catch((error) => console.log('error', error))
  }, [props.idboleto])

  async function getNotaBoleto(idboleto) {
    setIsload(true)
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      codcoligada: props.codcoligada,
      codfilial: props.codfilial,
      idboleto: idboleto,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    let nfse = await fetch(`${api}/dadosnfse`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status} ${response.statusText}`)
        }
        return response.json()
      })
      .then((data) => {
        setIsload(false)
        if (data.record_count > 0) {
          // localStorage.setItem('@meuboleto-app/filial', data.dados[0].CODFILIAL)
          setUser({
            ...user,
            idempresaenotas: data.dados[0].IDEMPRESAENOTAS,
            codfilial: data.dados[0].CODFILIAL,
          })
          return data.dados.find((obj) => obj.IDLAN === props.idLan)
        } else {
          return false
        }
      })
      .catch((error) => {
        setIsload(false)
        return error
      })

    return nfse
  }

  async function getNotaFiscal(idemp, idmov) {
    // console.log(idmov)
    setIsload(true)

    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append(
      'Authorization',
      'Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw'
    )

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(
      `https://api.enotasgw.com.br/v1/empresas/${user.idempresaenotas}/nfes/porIdExterno/${idmov}`,
      requestOptions
    )
      .then((response) => {
        setIsload(false)
        if (!response.ok) throw new Error(response.status)
        else return response.json()
      })
      .then((json) => {
        console.log(json)
        var MsgText = ''
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Id. Externo: <strong>${json.idExterno}</strong><br>`
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Status: <strong>${json.status}</strong><br>`
        if (json.status !== 'Autorizada') {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Obs Status: <strong>${json.motivoStatus}</strong><br>`
        }
        if (json.status === 'Autorizada') {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Número da nota: <strong>${json.numero}</strong><br>`
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Protocolo: <strong>${json.codigoVerificacao}</strong><br>`
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Data da autorização: <strong>${moment(
              json.dataAutorizacao
            ).format('DD/MM/YYYY')}</strong><br>`
        }
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Competência: <strong>${moment(
            json.dataCompetenciaRps
          ).format('MM/YYYY')}</strong><br>`

        const icone =
          json.status === 'Negada'
            ? 'error'
            : json.status === 'Autorizada'
            ? 'success'
            : 'warning'

        if (json.status === 'Autorizada') {
          console.log(json)
          window.open(json.linkDownloadPDF, '_blank')
        }
      })
      .catch((error) => {
        Swal.fire({
          title: '<span style="font-size: 22px;">Consulta de Nota</span>',
          html: `<p align="center" style="font-size: 16px;">A Nota fiscal não foi encontrada no eNotas. Por favor, verifique se o id ${idmov} foi enviado pelo TOTVS</p>`,
          icon: 'error',
          confirmButtonText: 'Sair',
        })
      })
  }

  return (
    <>
      {isVisible ? (
        <>
          <Button
            variant="success"
            className={`btn-block btn`}
            style={{
              cursor: 'pointer',
              fontSize: '0.7rem',
              backgroundColor: primaryColor,
              color: '#fff',
              padding: '6px',
            }}
            onClick={() => {
              // getNotaFiscal(props.idempresa, props.idboleto);
              getNotaBoleto(props.idboleto)
                .then((data) => {
                  getNotaFiscal(props.idempresa, data.IDMOV)
                    .then((dataNfse) => {
                      // console.log('Baixando....');
                    })
                    .catch((error) => console.log('error', error))
                })
                .catch((error) => console.log('error', error))
            }}
          >
            {isload ? (
              <SpinnerCircular
                color="rgba(255, 255, 255, 1)"
                secondaryColor="rgba(0, 0, 0, 0.44)"
                size={20}
              />
            ) : (
              <>
                <i class="fas fa-print" style={{ marginRight: '5px' }}></i>NFSe
              </>
            )}
          </Button>
        </>
      ) : (
        <Button
          variant="success"
          className={`btn-block btn`}
          style={{
            cursor: 'pointer',
            fontSize: '0.7rem',
            backgroundColor: primaryColor,
            color: '#fff',
            padding: '6px',
          }}
        >
          <i className="fas fa-dollar-sign" style={{ marginRight: '5px' }}></i>{' '}
          Pago
        </Button>
      )}
    </>
  )
}

export default BtnNfse
