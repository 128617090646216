import { useEffect, useState } from 'react'
//import ReactGA from "react-ga";

//const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID;
//ReactGA.initialize(googleAnalitcsID);

const api = process.env.REACT_APP_HOST

function Servicos(props) {
  const [nomeservico, setNomeservico] = useState()
  const [nservico, setNservico] = useState()

  useEffect(() => {
    // Repasso os dados para o Google Analitcs
    //ReactGA.pageview(window.location.pathname + window.location.search);

    async function serviceName() {
      const nmserv = await fetch(
        `${api}/listaservicosboleto?ra=${localStorage.getItem(
          '@meuboleto/ra'
        )}&coligada=${props.codcoligada}&idlan=${props.idlan}`
      )
        .then((response) => response.json())
        .then((result) => result.dados[0].NOME)
      setNservico(nmserv)
      setNomeservico(nmserv)
      // setNomeservico(nmserv.length > 25
      // ? nmserv.substring(0, 25 - 2) + "..."
      // : nmserv);
    }

    try {
      serviceName()
    } catch (erro) {
      console.log(erro)
    }
  }, [props.idboleto])

  return (
    <div
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <span name={'nmserv' + props.idboleto} id={'nmserv' + props.idboleto}>
        {/* {props.valor ? props.valor + ' - ' + nomeservico : nomeservico} */}
        {props.valor ? props.valor + ' - ' + nomeservico : nomeservico}
      </span>
      {/* <UncontrolledTooltip
        delay={0}
        trigger="hover focus"
        target={"nmserv" + props.idboleto}
      >
        {nservico}
      </UncontrolledTooltip> */}
    </div>
  )
}

export default Servicos
