import React, { useEffect, useState } from 'react'
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  CardTitle,
} from 'reactstrap'
import PIX from 'react-qrcode-pix'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import moment from 'moment'
import { lighten } from 'polished'
//import fetch from '../hooks/fetchWithTimeout';
import { SpinnerCircular } from 'spinners-react'
import Swal from 'sweetalert2'
import Servico from '../components/Other/Servicos'
import BtnPix from '../components/Other/BtnPix'
import BtnBoleto from '../components/Other/BtnBoleto'
import SolicitarBoletos from '../components/Other/BtnSolicitarBoleto'
import AgrupaBoletos from '../components/Other/AgrupaBoletos'
// import BtnCartao from '../components/Other/BtnCartao';
import CollapseTurmas from '../components/Collapse/CollapseTurmas'
import BtnNfse from '../components/Other/BtnNfse'
import Carregando from '../components/Cards/Carregando'
import CardSemDados from '../components/Cards/SemDados'
import { AuthProvider, useAuth } from '../providers/auth'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../assets/css/master.css'
import ReactGA from 'react-ga4'

const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID
ReactGA.initialize(googleAnalitcsID)

const userDec = process.env.REACT_APP_USER_API
const passDec = process.env.REACT_APP_USER_PSW

const api = process.env.REACT_APP_HOST
const api_2 = process.env.REACT_APP_HOST_2
const api_matriculei = process.env.REACT_APP_API_MATRICULEI

const initialvalue = {
  parcelas: '',
}

const fetchData = async (coligada, filial) => {
  try {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    if (coligada !== null && filial !== null) {
      var raw = JSON.stringify({
        coligada: coligada,
        filial: filial,
      })
    } else {
      var raw = JSON.stringify({
        coligada: coligada,
        filial: 1,
      })
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    const response = await fetch(`${api}/logo`, requestOptions)
    const result = await response.json()

    if (result.record_count > 0) {
      // Identidade da Marca
      const newPrimaryColor = result.dados.bgcolor
        ? result.dados.bgcolor
        : '#1A508C'
      const newLighterPrimaryColor = lighten(0.2, newPrimaryColor)

      return {
        background: `linear-gradient(to right, ${newPrimaryColor}, ${newLighterPrimaryColor})`,
        width: '100%',
      }
    } else {
      // localStorage.setItem('@meuboleto/logo', imageFail);
    }
  } catch (error) {
    console.log('error', error)
  }
}

const Tables = ({ gruposPorTurma }) => {
  const { user, setUser } = useAuth()
  const [isloading, setIsloading] = useState(null)
  const [selectedOption, setSelectedOption] = useState(initialvalue)
  const [qtdparcelas, setqtdparcelas] = useState('')
  const [validboleto, setValidboleto] = useState('')
  const [validlan, setValidlan] = useState('')
  const [ipte, setIpte] = useState('')
  const [mdstatusagendamento, setMdStatusAgendamento] = useState('')
  const [AuthDecodificar, setAuthDecodificar] = useState('')

  // Identidade da Marca
  const primaryColor = localStorage.getItem('@meuboleto/bgcolor')
    ? localStorage.getItem('@meuboleto/bgcolor')
    : '#1A508C'
  const lighterPrimaryColor = lighten(0.2, primaryColor)
  const [gradientStyle, setGradientStyle] = useState({
    background: `linear-gradient(to right, ${primaryColor}, ${lighterPrimaryColor})`,
    width: '100%',
  })

  // States do Header
  const [ra, setRa] = useState('')
  const [codColigada, setCodcoligada] = useState('')
  const [codFilial, setCodFilial] = useState('')
  const [codEditora, setCodEditora] = useState('')
  const [dadosturmas, setDadosturmas] = useState([])
  const [dadosmensalidades, setdadosMensalidades] = useState([])
  const [dadosmaterial, setdadosMaterial] = useState([])
  const [dadosparamsmd, setdadosParamsMd] = useState([])
  const [isLoadTurmas, setisLoadTurmas] = useState(false)
  const [isloadparcagenda, setIsloadParcAgenda] = useState(false)
  const [isloadqtdparc, setloadqtdparc] = useState(false)
  const [isload, setload] = useState(false)
  const [contratouServico, setContratouServico] = useState(false)
  const [values, setValues] = useState(initialvalue)
  const [tipobtn, setTipobtn] = useState('')
  const [tipoparcelamento, settipoparcelamento] = useState('')
  const [totais, setTotais] = useState({ totalAberto: 0, totalPago: 0 })
  const [totaismd, setTotaisMd] = useState({ totalAberto: 0, totalPago: 0 })
  const [totmensalidades, setTotmensalidades] = useState(
    <Skeleton height={20} />
  )
  const [totmensalidadespagas, setTotmensalidadespagas] = useState(
    <Skeleton height={20} />
  )
  const [totmensalidadesabertas, setTotmensalidadesabertas] = useState(
    <Skeleton height={20} />
  )
  const [totmmaterial, setTotmmaterial] = useState(<Skeleton height={20} />)
  const [totmaterialpagas, setTotmaterialpagas] = useState(
    <Skeleton height={20} />
  )
  const [totmaterialabertas, setTotmaterialabertas] = useState(
    <Skeleton height={20} />
  )
  const [codperlet, setcodperlet] = useState(2021)
  const [codturma, setcodturma] = useState('')
  const [desconto, setdesconto] = useState('')
  const [tpdesc, settpdesc] = useState('')
  const [tembolsa, setTembolsa] = useState(0)

  const [servicos, setservicos] = useState([])
  const [nmservicos, setnmservicos] = useState([])
  const [dadosagendados, setDadosagendados] = useState([])
  const [dadosturmasperlet, setDadosturmasperlet] = useState([])

  const [modalagendamento, setModalagendamento] = useState(false)
  const [modalagendar, setModalagendar] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalservicos, setModalservicos] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  //const toggleServicos = () => setModalservicos(!modalservicos);
  // const abrir = () => setModal2(!modal2);
  const abrir = () => setModal2(false)
  const abriropcao = () => setModalagendamento(!modalagendamento)
  const abriragendamento = () => setModalagendar(!modalagendar)

  const [qtdparcelascartao, setqtdparcelascartao] = useState([])
  const { height, width } = useWindowDimensions()

  const contarRegistros = (dados) => {
    let totalAberto = 0
    let totalPago = 0

    // Percorre o array de dados
    dados.forEach((registro) => {
      // Verifica se o LAN_STATUS é igual a "Aberto"
      if (
        registro.LAN_STATUS === 'Aberto' &&
        registro.BTN_STATUS !== 'NExibir'
      ) {
        totalAberto += 1
      }

      // Verifica se o LAN_STATUS é igual a "Pago"
      if (registro.LAN_STATUS === 'Pago' && registro.BTN_STATUS !== 'NExibir') {
        totalPago += 1
      }
    })

    // totalAberto =  totalAberto ? totalAberto : 0;
    // totalPago =  totalPago ? totalPago : 0;
    return { totalAberto, totalPago }
  }

  const contarRegistrosPorTurma = (dados, turmaDesejada) => {
    let totalAberto = 0
    let totalPago = 0

    // Percorre o array de dados
    dados.forEach((registro) => {
      // Verifica se o BTN_STATUS não é "NExibir" e se a turma é a desejada
      if (
        registro.BTN_STATUS !== 'NExibir' &&
        registro.CODTURMA === turmaDesejada
      ) {
        // Verifica se o LAN_STATUS é igual a "Aberto"
        if (registro.LAN_STATUS === 'Aberto') {
          totalAberto += 1
        }

        // Verifica se o LAN_STATUS é igual a "Pago"
        if (registro.LAN_STATUS === 'Pago') {
          totalPago += 1
        }
      }
    })

    return { totalAberto, totalPago }
  }

  useEffect(() => {
    let total = { totalAberto: 0, totalPago: 0 }
    let totalMd = { totalAberto: 0, totalPago: 0 }

    if (dadosmensalidades.length > 0) {
      total = contarRegistros(dadosmensalidades)
      // console.log('Total', total);
    } else {
      setTotais({ totalAberto: 0, totalPago: 0 })
    }

    if (dadosmaterial.length > 0) {
      totalMd = contarRegistros(dadosmaterial)
    } else {
      setTotaisMd({ totalAberto: 0, totalPago: 0 })
    }

    setTotais(total)
    setTotaisMd(totalMd)
  }, [dadosmensalidades, dadosmaterial])

  const [openCollapseIndex, setOpenCollapseIndex] = useState(null)

  const handleCollapseToggle = (index) => {
    if (openCollapseIndex === index) {
      setOpenCollapseIndex(null)
    } else {
      setOpenCollapseIndex(index)
    }
  }

  function toggle() {
    setModal(!modal)
  }

  function toggleServicos() {
    setModalservicos(!modalservicos)
  }

  const getMaterialDidatico = async (coligada, codturma, codperlet) => {
    var myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=ppvr57m0uuj42g8t10f947i2tf7j4jup')

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    fetch(
      `${api}/listamaterialh?ra=${localStorage.getItem(
        '@meuboleto/ra'
      )}&coligada=${coligada}&codturma=${codturma}&codperlet=${codperlet}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setTotmmaterial(result.record_count)
        setdadosMaterial(result.dados)

        const resultado = result.dados.find(
          (rsdados) => rsdados.AGENDAMENTOSTATUS === 'PREAGENDADO'
        )

        if (resultado !== undefined) {
          /* Exibe o popup de MD Agendado */
          var myHeaders = new Headers()
          myHeaders.append(
            'Cookie',
            'ci_session=ppvr57m0uuj42g8t10f947i2tf7j4jup'
          )

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          }

          fetch(
            `${api}/listamaterialagendado?ra=${localStorage.getItem(
              '@meuboleto/ra'
            )}&coligada=${coligada}&codturma=${codturma}&codperlet=${
              user.codperlet
            }`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              setIsloadParcAgenda(false)
              setDadosagendados(result.dados)
              setModalagendar(!modalagendar)
            })
            .catch((error) => {
              setIsloadParcAgenda(false)
              console.log('error', error)
            })
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const temAnuidade = (servico) => {
    if (servico.length > 0) {
      const servicoNomeMin = servico.toLowerCase()
      return servicoNomeMin.includes('anuidade')
    }
    return false
  }

  useEffect(() => {
    // O código dentro deste bloco será executado sempre que o "user" for alterado
    // console.log("O estado do user foi alterado:", user);

    let missingParams = []

    if (!localStorage.getItem('@meuboleto/ra')) {
      missingParams.push('ra')
    }

    if (!user.codcoligada) {
      missingParams.push('codcoligada')
    }

    if (!user.codturma) {
      missingParams.push('codturma')
    }

    if (!user.codperlet) {
      missingParams.push('codperlet')
    }

    if (missingParams.length === 0) {
      // @@@@@@ CARREGO O TOTAL DE MATERIAL @@@@@@@@@
      var myHeaders = new Headers()
      myHeaders.append('Cookie', 'ci_session=hbvv24nd9ua62qmkm3701acvqj6oi0v6')

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }
      fetch(
        `${api}/listamaterialh?ra=${localStorage.getItem(
          '@meuboleto/ra'
        )}&coligada=${user.codcoligada}&codturma=${user.codturma}&codperlet=${
          user.codperlet
        }`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setTotmmaterial(result.record_count)
          setdadosMaterial(result.dados)
          const resultado = result.dados.find(
            (rsdados) => rsdados.AGENDAMENTOSTATUS === 'PREAGENDADO'
          )

          if (resultado !== undefined) {
            /* Exibe o popup de MD Agendado */
            var myHeaders = new Headers()
            myHeaders.append(
              'Cookie',
              'ci_session=ppvr57m0uuj42g8t10f947i2tf7j4jup'
            )

            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow',
            }

            fetch(
              `${api}/listamaterialagendado?ra=${localStorage.getItem(
                '@meuboleto/ra'
              )}&coligada=${user.codcoligada}&codturma=${
                user.codturma
              }&codperlet=${user.codperlet}`,
              requestOptions
            )
              .then((response) => response.json())
              .then((result) => {
                setIsloadParcAgenda(false)
                setDadosagendados(result.dados)
                setModalagendar(!modalagendar)
              })
              .catch((error) => {
                setIsloadParcAgenda(false)
                console.log('error', error)
              })
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    } else {
      setdadosMaterial([])
    }
  }, [user])

  const servicoContratadoCartao = async (codcoligada, idboleto) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    let meioPgto = await fetch(
      `${api_matriculei}/api/MatriculaContratoParcela/ObterDadosParcela/${codcoligada}/${idboleto}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log('formaPagto', result.mensalidade.meioPgto === "CC" ? 'Deu bom' : 'Não contratou no CC')
        if (result.mensalidade.meioPgto === 'CC') {
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        console.log('error', error)
        return false
      })
    return meioPgto
  }

  useEffect(() => {
    let totais = []
    // Suponha que servicoContratadoCartao seja uma função assíncrona que retorna true ou false
    servicoContratadoCartao(user.codcoligada, validboleto ? validboleto : 0)
      .then((formaPagto) => {
        if (formaPagto === true) {
          setContratouServico(true)
        } else {
          setContratouServico(false)
        }
      })
      .catch((error) => {
        setContratouServico(false)
      })
  }, [validboleto])

  const renderizarItensPorTurma = (data) => {
    const gruposPorTurma = {}

    // Agrupa os itens por CODTURMA
    data.forEach((item) => {
      if (!gruposPorTurma[item.CODTURMA]) {
        gruposPorTurma[item.CODTURMA] = []
      }
      gruposPorTurma[item.CODTURMA].push(item)
    })

    // Renderiza os grupos
    return Object.keys(gruposPorTurma).map((codTurma, id) => {
      // totais.push(contarRegistrosPorTurma(dadosmensalidades,codTurma) );
      // console.log(codTurma,contarRegistrosPorTurma(dadosmensalidades,codTurma));

      return (
        <div key={codTurma}>
          <CollapseTurmas
            title={`${codTurma} - ${gruposPorTurma[codTurma][0].NOMETURMA}`}
            isOpenByDefault={id == 0 ? true : false}
            ra={gruposPorTurma[codTurma][0].RA}
            codcoligada={gruposPorTurma[codTurma][0].CODCOLIGADA}
            codfilial={gruposPorTurma[codTurma][0].CODFILIAL}
            codturma={codTurma}
            codperlet={gruposPorTurma[codTurma][0].CODPERLET}
            isOpen={openCollapseIndex === id}
            getMaterialDidatico={getMaterialDidatico}
            // getMaterialDidatico={getMaterialDidatico(gruposPorTurma[codTurma][0].CODCOLIGADA,codTurma,gruposPorTurma[codTurma][0].CODPERLET)}
            // loadLista={id == 0 ? getMaterialDidatico(gruposPorTurma[codTurma][0].CODCOLIGADA,codTurma,gruposPorTurma[codTurma][0].CODPERLET) : null}
          >
            <div className="col">
              <Card className="shadow" style={{ paddingBottom: '10px' }}>
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Mensalidades e Serviços - {user.codperlet}
                  </h3>
                </CardHeader>
                <Row style={{ marginLeft: '8px', marginBottom: '20px' }}>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              <span className="h2 font-weight-bold mb-0">
                                {gruposPorTurma[codTurma].length}
                              </span>
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">
                            Mensalidades e Serviços
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Pagas
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {/* {totmensalidadespagas} */}
                              {totais.totalPago}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-chart-pie" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">
                            Mensalidades pagas
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Em Aberto
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totais.totalAberto}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                              <i className="ni ni-calendar-grid-58" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">
                            Mensalidades em aberto
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* Só mostrar a Bolsa se ela existeir */}
                  {tembolsa > 0 ? <Col lg="6" xl="3"></Col> : ''}
                </Row>

                {width > 426 ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Serviço(s)</th>
                        <th scope="col">Boleto</th>
                        <th scope="col">Vencimento</th>
                        <th scope="col">Valor R$</th>
                        <th scope="col">Status</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {gruposPorTurma[codTurma].length > 0 ? (
                        gruposPorTurma[codTurma].map((itemBoleto, index) => {
                          var hj = new Date()
                          var vencto = new Date(itemBoleto.DTVENCTO) // já está no formato "DD/MM/YYYY"

                          var hoje = moment(hj).format('DD/MM/YYYY')
                          var vencimento = moment(itemBoleto.DTVENCTO).format(
                            'DD/MM/YYYY'
                          )

                          let date1 = moment(hj, 'DD-MM-YYYY').valueOf()
                          let date2 = moment(
                            itemBoleto.DTVENCTO,
                            'DD-MM-YYYY'
                          ).valueOf()

                          var statusbol = ''
                          var statucnab = itemBoleto.CNABSTATUS

                          switch (statucnab) {
                            case 0:
                              statusbol = 'Não Remetido'
                              break
                            case 1:
                              statusbol = 'Remetido'
                              break
                            case 2:
                              statusbol = 'Registrado'
                              break
                            case 3:
                              statusbol = 'Recusado'
                              break
                            case 4:
                              statusbol = 'Baixado'
                              break
                            case 5:
                              statusbol = 'Registrado Online'
                              break
                            case 6:
                              statusbol = 'Cancelado'
                              break
                            case 7:
                              statusbol = 'Pendente Remessa'
                              break
                            default:
                              console.log('default')
                              break
                          }
                          return (
                            <>
                              {
                                // itemBoleto.QTDLANCAMENTOS > 1 ? (<>
                                //       <AgrupaBoletos
                                //         key={itemBoleto.IDBOLETO}
                                //         servico="Diversos"
                                //         boleto={itemBoleto.IDBOLETO}
                                //         vencimento={itemBoleto.VENCIMENTO}
                                //         valor={itemBoleto.VALOR}
                                //         status="PAGO"
                                //       />
                                // </>) : (
                                //   <>
                                //     <tr key={`${itemBoleto.IDBOLETO}`}>
                                //       <th scope="row">
                                //         { }
                                //         <Media className="align-items-center">
                                //           <a className="avatar rounded-circle mr-3">
                                //             {itemBoleto.STATUS === 1 ? (
                                //               <img
                                //                 alt="..."
                                //                 src={
                                //                   require("../assets/img/theme/pago.jpg")
                                //                     .default
                                //                 }
                                //               />
                                //             ) : vencto < hj ? (
                                //               itemBoleto.CNABSTATUS === 2 ? (
                                //                 <img
                                //                   alt="..."
                                //                   src={
                                //                     require("../assets/img/theme/vencido.jpg")
                                //                       .default
                                //                   }
                                //                 />
                                //               ) : (
                                //                 <img
                                //                   alt="..."
                                //                   src={
                                //                     require("../assets/img/theme/indiponivel.jpg")
                                //                       .default
                                //                   }
                                //                 />
                                //               )
                                //             ) : (
                                //               <img
                                //                 alt="..."
                                //                 src={
                                //                   require("../assets/img/theme/imprimir.jpg")
                                //                     .default
                                //                 }
                                //               />
                                //             )}
                                //           </a>
                                //           {itemBoleto.QTDLANCAMENTOS} -
                                //           <Media>
                                //             <span className="mb-0 text-sm">
                                //               {itemBoleto.QTDLANCAMENTOS > 1 ? (
                                //                 <Diversos
                                //                   idboleto={itemBoleto.IDBOLETO}
                                //                 />
                                //               ) : (
                                //                 <Servico
                                //                   codcoligada={itemBoleto.CODCOLIGADA}
                                //                   idboleto={itemBoleto.IDBOLETO}
                                //                 />
                                //               )}
                                //             </span>
                                //           </Media>
                                //         </Media>
                                //       </th>
                                //       <td>{itemBoleto.IDBOLETO}</td>
                                //       <td>{itemBoleto.VENCIMENTO}</td>
                                //       <td>{itemBoleto.VALOR}</td>
                                //       <td>
                                //         <Badge color="" className="badge-dot mr-4">
                                //           {itemBoleto.BTN_STATUS === "Pago" ? (
                                //             <>
                                //               <i className="bg-success" />
                                //               {itemBoleto.LAN_STATUS}
                                //             </>
                                //           ) : null}

                                //           {itemBoleto.BTN_STATUS === "Imprimir" ? (
                                //             <>
                                //               <i className="bg-primary" />
                                //               {itemBoleto.LAN_STATUS}
                                //             </>
                                //           ) : null}

                                //           {itemBoleto.BTN_STATUS === "Solicitar" ? (
                                //             <>
                                //               <i className="bg-primary" />
                                //               Indisponível
                                //             </>
                                //           ) : null}

                                //           {itemBoleto.BTN_STATUS === "BoletonaoRegistrado" ? (
                                //             <>
                                //               <i className="bg-primary" />
                                //               {itemBoleto.LAN_STATUS}
                                //             </>
                                //           ) : null}
                                //         </Badge>
                                //       </td>
                                //       <td>
                                //         <div className="align-items-center">
                                //           {itemBoleto.BTN_STATUS === "Imprimir" ? (
                                //             <>
                                //               <BtnBoleto
                                //                 ra={itemBoleto.RA}
                                //                 codcoligada={itemBoleto.CODCOLIGADA}
                                //                 codfilial={itemBoleto.CODFILIAL}
                                //                 codperlet={itemBoleto.CODPERLET}
                                //                 codturma={itemBoleto.CODTURMA}
                                //                 idboleto={itemBoleto.IDBOLETO}
                                //                 statusboleto={statucnab}
                                //                 ipte={itemBoleto.IPTE}
                                //                 codbarras={itemBoleto.CODIGOBARRAS}
                                //                 tipo="mensalidade"
                                //               />
                                //               <br />
                                //               {temAnuidade(itemBoleto.SERVICONOME) ? null : <BtnCartao
                                //                 ra={itemBoleto.RA}
                                //                 codcoligada={itemBoleto.CODCOLIGADA}
                                //                 codfilial={itemBoleto.CODFILIAL}
                                //                 codperlet={itemBoleto.CODPERLET}
                                //                 codturma={itemBoleto.CODTURMA}
                                //                 idboleto={itemBoleto.IDBOLETO}
                                //                 tipoparcelamento="Mensalidade"
                                //               />}
                                //               {
                                //                 <BtnPix
                                //                   ra={itemBoleto.RA}
                                //                   codcoligada={itemBoleto.CODCOLIGADA}
                                //                   codfilial={itemBoleto.CODFILIAL}
                                //                   codperlet={itemBoleto.CODPERLET}
                                //                   codturma={itemBoleto.CODTURMA}
                                //                   idboleto={itemBoleto.IDBOLETO}
                                //                   tipoparcelamento="Mensalidade"
                                //                 />
                                //               }
                                //             </>
                                //           )
                                //             : null}

                                //           {itemBoleto.BTN_STATUS === "NExibir" ? (
                                //             <></>
                                //           ) : null}

                                //           {itemBoleto.BTN_STATUS === "Pago" ? (
                                //             <>
                                //               {user.idempresaenotas !== "NAO_INTEGRADA" ? (
                                //                 <>
                                //                   {
                                //                     <BtnNfse
                                //                       codcoligada={itemBoleto.CODCOLIGADA}
                                //                       codfilial={itemBoleto.CODFILIAL}
                                //                       idempresa={user.idempresaenotas}
                                //                       idboleto={itemBoleto.IDBOLETO}
                                //                     />
                                //               /* <a
                                //                 className={`btn btn-${localStorage.getItem(
                                //                   "@meuboleto-app/bgcolor"
                                //                 )} btn-block`}
                                //               >
                                //                 PAGO
                                //               </a>*/}
                                //                 </>
                                //               ) : (
                                //                 <a
                                //                   className={`btn btn-${localStorage.getItem(
                                //                     "@meuboleto-app/bgcolor"
                                //                   )} btn-block`}
                                //                 >
                                //                   PAGO
                                //                 </a>
                                //               )}
                                //             </>
                                //           ) : null}

                                //           {itemBoleto.BTN_STATUS === "Solicitar" ? (
                                //             <>
                                //               <SolicitarBoletos
                                //                 codcoligada={itemBoleto.CODCOLIGADA}
                                //                 statucnab={itemBoleto.CNABSTATUS}
                                //                 idboleto={itemBoleto.IDBOLETO}
                                //                 idlan={itemBoleto.IDLAN}
                                //               />
                                //               <br />
                                //               {temAnuidade(itemBoleto.SERVICONOME) ? null :
                                //                 <BtnCartao
                                //                   codcoligada={itemBoleto.CODCOLIGADA}
                                //                   idboleto={itemBoleto.IDBOLETO}
                                //                   tipoparcelamento="Mensalidade"
                                //                 />
                                //               }
                                //               {
                                //                 // <BtnPix
                                //                 //   idboleto={itemBoleto.IDBOLETO}
                                //                 //   tipoparcelamento="Mensalidade"
                                //                 // />
                                //               }
                                //             </>

                                //           ) : null}

                                //           {itemBoleto.BTN_STATUS === "BoletonaoRegistrado" ? (
                                //             <>
                                //               {
                                //                 // Habilitado somente para o Banco do Brasil
                                //                 itemBoleto.NUMBANCO === "001" ? (
                                //                   <SolicitarBoletos
                                //                     codcoligada={itemBoleto.CODCOLIGADA}
                                //                     statucnab={itemBoleto.CNABSTATUS}
                                //                     idboleto={itemBoleto.IDBOLETO}
                                //                     idlan={itemBoleto.IDLAN}
                                //                   />
                                //                 ) : (
                                //                   <BtnBoleto
                                //                     ra={itemBoleto.RA}
                                //                     codcoligada={itemBoleto.CODCOLIGADA}
                                //                     idboleto={itemBoleto.IDBOLETO}
                                //                     statusboleto={statucnab}
                                //                     ipte={itemBoleto.IPTE}
                                //                     codbarras={itemBoleto.CODIGOBARRAS}
                                //                     tipo="mensalidade"
                                //                     acao="solicitar"
                                //                   />
                                //                 )
                                //               }
                                //               <br />
                                //               {temAnuidade(itemBoleto.SERVICONOME) ? null :
                                //                 <BtnCartao
                                //                   codcoligada={itemBoleto.CODCOLIGADA}
                                //                   idboleto={itemBoleto.IDBOLETO}
                                //                   tipoparcelamento="Mensalidade"
                                //                 />
                                //               }
                                //             </>
                                //           ) : null}

                                //         </div>
                                //       </td>
                                //     </tr>
                                //   </>
                                // )
                                <tr key={`${itemBoleto.IDBOLETO}`}>
                                  <th scope="row">
                                    {}
                                    <Media className="align-items-center">
                                      <a className="avatar rounded-circle mr-3">
                                        {itemBoleto.STATUS === 1 ? (
                                          <img
                                            alt="..."
                                            src={
                                              require('../assets/img/theme/pago.jpg')
                                                .default
                                            }
                                          />
                                        ) : vencto < hj ? (
                                          itemBoleto.CNABSTATUS === 2 ? (
                                            <img
                                              alt="..."
                                              src={
                                                require('../assets/img/theme/vencido.jpg')
                                                  .default
                                              }
                                            />
                                          ) : (
                                            <img
                                              alt="..."
                                              src={
                                                require('../assets/img/theme/indiponivel.jpg')
                                                  .default
                                              }
                                            />
                                          )
                                        ) : (
                                          <img
                                            alt="..."
                                            src={
                                              require('../assets/img/theme/imprimir.jpg')
                                                .default
                                            }
                                          />
                                        )}
                                      </a>
                                      {/* {itemBoleto.QTDLANCAMENTOS} - */}
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {itemBoleto.QTDLANCAMENTOS > 1 ? (
                                            <Diversos
                                              idboleto={itemBoleto.IDBOLETO}
                                            />
                                          ) : (
                                            <Servico
                                              codcoligada={
                                                itemBoleto.CODCOLIGADA
                                              }
                                              idboleto={itemBoleto.IDBOLETO}
                                            />
                                          )}
                                        </span>
                                      </Media>
                                    </Media>
                                  </th>
                                  <td>{itemBoleto.IDBOLETO}</td>
                                  <td>{itemBoleto.VENCIMENTO}</td>
                                  <td>{itemBoleto.VALOR}</td>
                                  <td>
                                    <Badge color="" className="badge-dot mr-4">
                                      {itemBoleto.BTN_STATUS === 'Pago' ? (
                                        <>
                                          <i className="bg-success" />
                                          {itemBoleto.LAN_STATUS}
                                        </>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS === 'Imprimir' ? (
                                        <>
                                          <i className="bg-primary" />
                                          {itemBoleto.LAN_STATUS}
                                        </>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS === 'Solicitar' ? (
                                        <>
                                          <i className="bg-primary" />
                                          Indisponível
                                        </>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS ===
                                      'BoletonaoRegistrado' ? (
                                        <>
                                          <i className="bg-primary" />
                                          {itemBoleto.LAN_STATUS}
                                        </>
                                      ) : null}
                                    </Badge>
                                  </td>
                                  <td>
                                    <div className="align-items-center">
                                      {itemBoleto.BTN_STATUS === 'Imprimir' ? (
                                        <>
                                          <BtnBoleto
                                            ra={itemBoleto.RA}
                                            codcoligada={itemBoleto.CODCOLIGADA}
                                            codfilial={itemBoleto.CODFILIAL}
                                            codperlet={itemBoleto.CODPERLET}
                                            codturma={itemBoleto.CODTURMA}
                                            idboleto={itemBoleto.IDBOLETO}
                                            statusboleto={statucnab}
                                            ipte={itemBoleto.IPTE}
                                            codbarras={itemBoleto.CODIGOBARRAS}
                                            tipo="mensalidade"
                                          />
                                          <br />
                                          {temAnuidade(
                                            itemBoleto.SERVICONOME
                                          ) ? null : (
                                            <BtnCartao
                                              ra={itemBoleto.RA}
                                              codcoligada={
                                                itemBoleto.CODCOLIGADA
                                              }
                                              codfilial={itemBoleto.CODFILIAL}
                                              codperlet={itemBoleto.CODPERLET}
                                              codturma={itemBoleto.CODTURMA}
                                              idboleto={itemBoleto.IDBOLETO}
                                              tipoparcelamento="Mensalidade"
                                            />
                                          )}
                                          {
                                            <BtnPix
                                              ra={itemBoleto.RA}
                                              codcoligada={
                                                itemBoleto.CODCOLIGADA
                                              }
                                              codfilial={itemBoleto.CODFILIAL}
                                              codperlet={itemBoleto.CODPERLET}
                                              codturma={itemBoleto.CODTURMA}
                                              idboleto={itemBoleto.IDBOLETO}
                                              tipoparcelamento="Mensalidade"
                                            />
                                          }
                                        </>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS === 'NExibir' ? (
                                        <></>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS === 'Pago' ? (
                                        <>
                                          {user.idempresaenotas !==
                                          'NAO_INTEGRADA' ? (
                                            <>
                                              {
                                                <BtnNfse
                                                  codcoligada={
                                                    itemBoleto.CODCOLIGADA
                                                  }
                                                  codfilial={
                                                    itemBoleto.CODFILIAL
                                                  }
                                                  idempresa={
                                                    user.idempresaenotas
                                                  }
                                                  idboleto={itemBoleto.IDBOLETO}
                                                />
                                                /* <a
                                                className={`btn btn-${localStorage.getItem(
                                                  "@meuboleto-app/bgcolor"
                                                )} btn-block`}
                                              >
                                                PAGO
                                              </a>*/
                                              }
                                            </>
                                          ) : (
                                            <>
                                              <a
                                                style={{
                                                  backgroundColor: primaryColor,
                                                  color: '#fff',
                                                }}
                                                className={`btn btn-${primaryColor} btn-block`}
                                              >
                                                PAGO
                                              </a>
                                            </>
                                          )}
                                        </>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS === 'Solicitar' ? (
                                        <>
                                          <SolicitarBoletos
                                            codcoligada={itemBoleto.CODCOLIGADA}
                                            statucnab={itemBoleto.CNABSTATUS}
                                            idboleto={itemBoleto.IDBOLETO}
                                            idlan={itemBoleto.IDLAN}
                                          />
                                          <br />
                                          {temAnuidade(
                                            itemBoleto.SERVICONOME
                                          ) ? null : (
                                            <BtnCartao
                                              codcoligada={
                                                itemBoleto.CODCOLIGADA
                                              }
                                              idboleto={itemBoleto.IDBOLETO}
                                              tipoparcelamento="Mensalidade"
                                            />
                                          )}
                                          {
                                            // <BtnPix
                                            //   idboleto={itemBoleto.IDBOLETO}
                                            //   tipoparcelamento="Mensalidade"
                                            // />
                                          }
                                        </>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS ===
                                      'BoletonaoRegistrado' ? (
                                        <>
                                          {
                                            // Habilitado somente para o Banco do Brasil
                                            itemBoleto.NUMBANCO === '001' ? (
                                              <SolicitarBoletos
                                                codcoligada={
                                                  itemBoleto.CODCOLIGADA
                                                }
                                                statucnab={
                                                  itemBoleto.CNABSTATUS
                                                }
                                                idboleto={itemBoleto.IDBOLETO}
                                                idlan={itemBoleto.IDLAN}
                                              />
                                            ) : (
                                              <BtnBoleto
                                                ra={itemBoleto.RA}
                                                codcoligada={
                                                  itemBoleto.CODCOLIGADA
                                                }
                                                idboleto={itemBoleto.IDBOLETO}
                                                statusboleto={statucnab}
                                                ipte={itemBoleto.IPTE}
                                                codbarras={
                                                  itemBoleto.CODIGOBARRAS
                                                }
                                                tipo="mensalidade"
                                                acao="solicitar"
                                              />
                                            )
                                          }
                                          <br />
                                          {temAnuidade(
                                            itemBoleto.SERVICONOME
                                          ) ? null : (
                                            <BtnCartao
                                              codcoligada={
                                                itemBoleto.CODCOLIGADA
                                              }
                                              idboleto={itemBoleto.IDBOLETO}
                                              tipoparcelamento="Mensalidade"
                                            />
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr>
                              }
                            </>
                          )
                        })
                      ) : (
                        <>
                          {dadosmensalidades.length === 0 ? (
                            <TableSkeleton />
                          ) : (
                            <tr>
                              <td colSpan="6" style={{ textAlign: 'center' }}>
                                <CardSemDados />
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <CardBody>
                    {dadosmensalidades.length > 0 ? (
                      dadosmensalidades.map((itemBoleto, index) => {
                        var hj = new Date()
                        var vencto = new Date(itemBoleto.DTVENCTO) // já está no formato "DD/MM/YYYY"
                        var statusbol = ''
                        var statucnab = itemBoleto.CNABSTATUS

                        switch (statucnab) {
                          case 0:
                            statusbol = 'Não Remetido'
                            break
                          case 1:
                            statusbol = 'Remetido'
                            break
                          case 2:
                            statusbol = 'Registrado'
                            break
                          case 3:
                            statusbol = 'Recusado'
                            break
                          case 4:
                            statusbol = 'Baixado'
                            break
                          case 5:
                            statusbol = 'Registrado Online'
                            break
                          case 6:
                            statusbol = 'Cancelado'
                            break
                          case 7:
                            statusbol = 'Pendente Remessa'
                            break
                          default:
                            console.log('default')
                        }
                        return (
                          <Row className="icon-examples">
                            <Col class="col-12">
                              <div
                                style={{
                                  backgroundColor: '#f5f5f5',
                                  borderRadius: 5,
                                  color: '#32325d',
                                  fontSize: 18,
                                  margin: 0,
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: '#ebeced',
                                    height: '28px',
                                  }}
                                >
                                  <span
                                    style={{
                                      float: 'left',
                                      color: '#707070',
                                      textAlign: 'left',
                                      fontSize: 14,
                                      marginTop: 3,
                                      marginLeft: 10,
                                    }}
                                  >
                                    Vencimento: {itemBoleto.VENCIMENTO}
                                  </span>
                                  <span
                                    style={{
                                      float: 'right',
                                      color: '#707070',
                                      textAlign: 'left',
                                      fontSize: 12,
                                    }}
                                  >
                                    <Badge color="" className="badge-dot mr-4">
                                      {itemBoleto.BTN_STATUS === 'Pago' ? (
                                        <>
                                          <i className="bg-success" />
                                          {itemBoleto.LAN_STATUS}
                                        </>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS === 'Imprimir' ? (
                                        <>
                                          <i className="bg-primary" />
                                          {itemBoleto.LAN_STATUS}
                                        </>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS === 'Solicitar' ? (
                                        <>
                                          <i className="bg-primary" />
                                          Indisponível
                                        </>
                                      ) : null}

                                      {itemBoleto.BTN_STATUS ===
                                      'BoletonaoRegistrado' ? (
                                        <>
                                          <i className="bg-primary" />
                                          {itemBoleto.LAN_STATUS}
                                        </>
                                      ) : null}
                                    </Badge>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    marginLeft: 10,
                                    marginTop: 5,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  <a className="avatar rounded-circle mr-3">
                                    {itemBoleto.STATUS === 1 ? (
                                      <img
                                        alt="..."
                                        src={
                                          require('../assets/img/theme/pago.jpg')
                                            .default
                                        }
                                      />
                                    ) : vencto < hj ? (
                                      itemBoleto.CNABSTATUS === 2 ? (
                                        <img
                                          alt="..."
                                          src={
                                            require('../assets/img/theme/vencido.jpg')
                                              .default
                                          }
                                        />
                                      ) : (
                                        <img
                                          alt="..."
                                          src={
                                            require('../assets/img/theme/indiponivel.jpg')
                                              .default
                                          }
                                        />
                                      )
                                    ) : (
                                      <img
                                        alt="..."
                                        src={
                                          require('../assets/img/theme/imprimir.jpg')
                                            .default
                                        }
                                      />
                                    )}
                                  </a>
                                  <span
                                    style={{
                                      fontSize: 14,
                                      marginTop: 10,
                                      color: '#000',
                                    }}
                                  >
                                    <span className="mb-0 text-sm">
                                      {itemBoleto.QTDLANCAMENTOS > 1 ? (
                                        <>
                                          {' '}
                                          <span>{itemBoleto.VALOR}</span> -{' '}
                                          <Diversos
                                            idboleto={itemBoleto.IDBOLETO}
                                          />{' '}
                                        </>
                                      ) : (
                                        <>
                                          {' '}
                                          <span>{itemBoleto.VALOR}</span> -{' '}
                                          <Servico
                                            idboleto={itemBoleto.IDBOLETO}
                                          />{' '}
                                        </>
                                      )}
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    marginBottom: 10,
                                    marginTop: 10,
                                  }}
                                  className="align-items-center"
                                >
                                  {itemBoleto.BTN_STATUS === 'Imprimir' ? (
                                    <>
                                      <BtnBoleto
                                        ra={itemBoleto.RA}
                                        codcoligada={itemBoleto.CODCOLIGADA}
                                        codfilial={itemBoleto.CODFILIAL}
                                        codperlet={itemBoleto.CODPERLET}
                                        codturma={itemBoleto.CODTURMA}
                                        idboleto={itemBoleto.IDBOLETO}
                                        statusboleto={statucnab}
                                        ipte={itemBoleto.IPTE}
                                        codbarras={itemBoleto.CODIGOBARRAS}
                                        tipo="mensalidade"
                                      />
                                      <br />
                                      {temAnuidade(
                                        itemBoleto.SERVICONOME
                                      ) ? null : (
                                        <BtnCartao
                                          ra={itemBoleto.RA}
                                          codcoligada={itemBoleto.CODCOLIGADA}
                                          codfilial={itemBoleto.CODFILIAL}
                                          codperlet={itemBoleto.CODPERLET}
                                          codturma={itemBoleto.CODTURMA}
                                          idboleto={itemBoleto.IDBOLETO}
                                          tipoparcelamento="Mensalidade"
                                        />
                                      )}
                                      {
                                        // <BtnPix
                                        //   idboleto={itemBoleto.IDBOLETO}
                                        //   tipoparcelamento="Mensalidade"
                                        // />
                                      }
                                    </>
                                  ) : null}

                                  {itemBoleto.BTN_STATUS === 'NExibir' ? (
                                    <></>
                                  ) : null}

                                  {itemBoleto.BTN_STATUS === 'Pago' ? (
                                    <>
                                      <a
                                        style={{
                                          backgroundColor: primaryColor,
                                          color: '#fff',
                                        }}
                                        className={`btn btn-${primaryColor} btn-block`}
                                      >
                                        PAGO
                                      </a>
                                    </>
                                  ) : null}

                                  {itemBoleto.BTN_STATUS === 'Solicitar' ? (
                                    <>
                                      <SolicitarBoletos
                                        codcoligada={itemBoleto.CODCOLIGADA}
                                        statucnab={itemBoleto.CNABSTATUS}
                                        idboleto={itemBoleto.IDBOLETO}
                                        idlan={itemBoleto.IDLAN}
                                      />
                                      <br />
                                      {temAnuidade(
                                        itemBoleto.SERVICONOME
                                      ) ? null : (
                                        <BtnCartao
                                          ra={itemBoleto.RA}
                                          codcoligada={itemBoleto.CODCOLIGADA}
                                          codfilial={itemBoleto.CODFILIAL}
                                          codperlet={itemBoleto.CODPERLET}
                                          codturma={itemBoleto.CODTURMA}
                                          idboleto={itemBoleto.IDBOLETO}
                                          tipoparcelamento="Mensalidade"
                                        />
                                      )}
                                      {
                                        // <BtnPix
                                        //   idboleto={itemBoleto.IDBOLETO}
                                        //   tipoparcelamento="Mensalidade"
                                        // />
                                      }
                                    </>
                                  ) : null}

                                  {itemBoleto.BTN_STATUS ===
                                  'BoletonaoRegistrado' ? (
                                    <>
                                      {
                                        // Habilitado somente para o Banco do Brasil
                                        itemBoleto.NUMBANCO === '001' ? (
                                          <SolicitarBoletos
                                            codcoligada={itemBoleto.CODCOLIGADA}
                                            statucnab={itemBoleto.CNABSTATUS}
                                            idboleto={itemBoleto.IDBOLETO}
                                            idlan={itemBoleto.IDLAN}
                                          />
                                        ) : (
                                          <BtnBoleto
                                            ra={itemBoleto.RA}
                                            codcoligada={itemBoleto.CODCOLIGADA}
                                            idboleto={itemBoleto.IDBOLETO}
                                            statusboleto={statucnab}
                                            ipte={itemBoleto.IPTE}
                                            codbarras={itemBoleto.CODIGOBARRAS}
                                            tipo="mensalidade"
                                            acao="solicitar"
                                          />
                                        )
                                      }
                                      <br />
                                      {temAnuidade(
                                        itemBoleto.SERVICONOME
                                      ) ? null : (
                                        <BtnCartao
                                          ra={itemBoleto.RA}
                                          codcoligada={itemBoleto.CODCOLIGADA}
                                          codfilial={itemBoleto.CODFILIAL}
                                          codperlet={itemBoleto.CODPERLET}
                                          codturma={itemBoleto.CODTURMA}
                                          idboleto={itemBoleto.IDBOLETO}
                                          tipoparcelamento="Mensalidade"
                                        />
                                      )}
                                    </>
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    height: 5,
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        )
                      })
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {dadosmensalidades.length === 0 ? (
                          <span>
                            <Loader
                              type="ThreeDots"
                              color="#9e9e9e"
                              height={50}
                              width={50}
                              // timeout={3000} //3 secs
                            />
                          </span>
                        ) : (
                          <>
                            <div style={{ textAlign: 'center' }}>
                              <span>
                                <CardSemDados />
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>

            <hr />

            <div style={{ marginTop: '30px' }}>
              {/* Table */}
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <h3 className="mb-0">
                        Material Didático - {user.codperlet}
                      </h3>
                    </CardHeader>
                    <div style={{ marginLeft: '20px', marginBottom: '20px' }}>
                      {/* Card stats */}
                      <Row>
                        <Col lg="6" xl="3">
                          <Card className="card-stats mb-4 mb-xl-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                  >
                                    Total
                                  </CardTitle>
                                  <span className="h2 font-weight-bold mb-0">
                                    <span className="h2 font-weight-bold mb-0">
                                      {totmmaterial}
                                    </span>
                                  </span>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                    <i className="fas fa-chart-bar" />
                                  </div>
                                </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-muted text-sm">
                                <span className="text-nowrap">
                                  Boletos de material
                                </span>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="6" xl="3">
                          <Card className="card-stats mb-4 mb-xl-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                  >
                                    Pagas
                                  </CardTitle>
                                  <span className="h2 font-weight-bold mb-0">
                                    {/* {totmaterialpagas} */}
                                    {totaismd.totalPago}
                                  </span>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                    <i className="fas fa-chart-pie" />
                                  </div>
                                </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-muted text-sm">
                                <span className="text-nowrap">
                                  Boletos de material pagos
                                </span>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="6" xl="3">
                          <Card className="card-stats mb-4 mb-xl-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                  >
                                    Em Aberto
                                  </CardTitle>
                                  <span className="h2 font-weight-bold mb-0">
                                    {/* {totmaterialabertas} */}
                                    {totaismd.totalAberto}
                                  </span>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                                    <i className="ni ni-calendar-grid-58" />
                                  </div>
                                </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-muted text-sm">
                                <span className="text-nowrap">
                                  Boletos de material aberto
                                </span>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                    {width > 426 ? (
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Serviço(s)</th>
                            <th scope="col">Boleto</th>
                            <th scope="col">Vencimento</th>
                            <th scope="col">Valor R$</th>
                            <th scope="col">Status</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {dadosmaterial.length > 0 ? (
                            dadosmaterial.map((itemMaterial, index) => {
                              var hj = new Date()
                              var vencto = new Date(itemMaterial.DTVENCTO) // já está no formato "DD/MM/YYYY"
                              var statusbol = ''
                              var statucnab = itemMaterial.CNABSTATUS

                              switch (statucnab) {
                                case 0:
                                  statusbol = 'Não Remetido'
                                  break
                                case 1:
                                  statusbol = 'Remetido'
                                  break
                                case 2:
                                  statusbol = 'Registrado'
                                  break
                                case 3:
                                  statusbol = 'Recusado'
                                  break
                                case 4:
                                  statusbol = 'Baixado'
                                  break
                                case 5:
                                  statusbol = 'Registrado Online'
                                  break
                                case 6:
                                  statusbol = 'Cancelado'
                                  break
                                case 7:
                                  statusbol = 'Pendente Remessa'
                                  break
                                default:
                                  console.log('default')
                              }
                              return (
                                <tr key={`${itemMaterial.IDBOLETO}`}>
                                  <th scope="row">
                                    {}
                                    <Media className="align-items-center">
                                      <a className="avatar rounded-circle mr-3">
                                        {itemMaterial.STATUS === 1 ? (
                                          <img
                                            alt="..."
                                            src={
                                              require('../assets/img/theme/pago.jpg')
                                                .default
                                            }
                                          />
                                        ) : vencto < hj ? (
                                          itemMaterial.CNABSTATUS === 2 ? (
                                            <img
                                              alt="..."
                                              src={
                                                require('../assets/img/theme/vencido.jpg')
                                                  .default
                                              }
                                            />
                                          ) : (
                                            <img
                                              alt="..."
                                              src={
                                                require('../assets/img/theme/indiponivel.jpg')
                                                  .default
                                              }
                                            />
                                          )
                                        ) : (
                                          <img
                                            alt="..."
                                            src={
                                              require('../assets/img/theme/imprimir.jpg')
                                                .default
                                            }
                                          />
                                        )}
                                      </a>
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          Material Didático
                                        </span>
                                      </Media>
                                    </Media>
                                  </th>
                                  <td>{itemMaterial.IDBOLETO}</td>
                                  <td>{itemMaterial.VENCIMENTO}</td>
                                  <td>{itemMaterial.VALORLIQ}</td>
                                  <td>
                                    <Badge color="" className="badge-dot mr-4">
                                      {dadosparamsmd !== undefined ? (
                                        dadosparamsmd.PAGAGENDADOMD === 'S' ? (
                                          itemMaterial.AGENDAMENTOSTATUS ==
                                          'AGENDADO' ? (
                                            <>
                                              <i className="bg-success" />
                                              Agendado
                                            </>
                                          ) : itemMaterial.AGENDAMENTOSTATUS ===
                                            'PREAGENDADO' ? (
                                            <>
                                              <i className="bg-success" />
                                              Pré-Agendado
                                            </>
                                          ) : itemMaterial.BTN_STATUS ===
                                            'Pago' ? (
                                            <>
                                              <i className="bg-success" />
                                              {itemMaterial.LAN_STATUS}
                                            </>
                                          ) : itemMaterial.BTN_STATUS ===
                                            'Imprimir' ? (
                                            <>
                                              <i className="bg-primary" />
                                              {itemMaterial.LAN_STATUS}
                                            </>
                                          ) : itemMaterial.BTN_STATUS ===
                                            'Solicitar' ? (
                                            <>
                                              <i className="bg-primary" />
                                              Indisponível
                                            </>
                                          ) : itemMaterial.BTN_STATUS ===
                                            'BoletonaoRegistrado' ? (
                                            <>
                                              <i className="bg-primary" />
                                              {itemMaterial.LAN_STATUS}
                                            </>
                                          ) : null
                                        ) : itemMaterial.BTN_STATUS ===
                                          'Pago' ? (
                                          <>
                                            <i className="bg-success" />
                                            {itemMaterial.LAN_STATUS}
                                          </>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'Imprimir' ? (
                                          <>
                                            <i className="bg-primary" />
                                            {itemMaterial.LAN_STATUS}
                                          </>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'Solicitar' ? (
                                          <>
                                            <i className="bg-primary" />
                                            Indisponível
                                          </>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'BoletonaoRegistrado' ? (
                                          <>
                                            <i className="bg-primary" />
                                            {itemMaterial.LAN_STATUS}
                                          </>
                                        ) : null
                                      ) : itemMaterial.BTN_STATUS === 'Pago' ? (
                                        <>
                                          <i className="bg-success" />
                                          {itemMaterial.LAN_STATUS}
                                        </>
                                      ) : itemMaterial.BTN_STATUS ===
                                        'Imprimir' ? (
                                        <>
                                          <i className="bg-primary" />
                                          {itemMaterial.LAN_STATUS}
                                        </>
                                      ) : itemMaterial.BTN_STATUS ===
                                        'Solicitar' ? (
                                        <>
                                          <i className="bg-primary" />
                                          Indisponível
                                        </>
                                      ) : itemMaterial.BTN_STATUS ===
                                        'BoletonaoRegistrado' ? (
                                        <>
                                          <i className="bg-primary" />
                                          {itemMaterial.LAN_STATUS}
                                        </>
                                      ) : null}
                                    </Badge>
                                  </td>
                                  <td>
                                    <div className="align-items-center">
                                      {dadosparamsmd !== undefined &&
                                      dadosparamsmd.PAGAGENDADOMD === 'S' ? (
                                        itemMaterial.AGENDAMENTOSTATUS ==
                                        'AGENDADO' ? (
                                          <>
                                            <BtnAgendamento
                                              idboleto={itemMaterial.IDBOLETO}
                                              idlan={itemMaterial.IDLAN}
                                              status={
                                                itemMaterial.AGENDAMENTOSTATUS
                                              }
                                              tipoparcelamento="MD"
                                              tipo="material"
                                              // titulobtn="PGTO. AGENDADO"
                                              titulobtn="CANCELAR AGENDAMENTO"
                                            />
                                            <br />
                                          </>
                                        ) : itemMaterial.AGENDAMENTOSTATUS ===
                                          'PREAGENDADO' ? (
                                          <>
                                            <BtnCartaoMD
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              idlan={itemMaterial.IDLAN}
                                              tipoparcelamento="MD"
                                              agendamentostatus="PREAGENDADO"
                                            />
                                          </>
                                        ) : /*itemMaterial.AGENDAMENTOSTATUS ==
                                              "CONCLUIDO" ||
                                              itemMaterial.AGENDAMENTOSTATUS ==
                                              "CANCELADO" ? (
                                              <>
                                                <BtnAgendamento
                                                  idboleto={itemMaterial.IDBOLETO}
                                                  idlan={itemMaterial.IDLAN}
                                                  status={
                                                    itemMaterial.AGENDAMENTOSTATUS
                                                  }
                                                  tipoparcelamento="MD"
                                                  tipo="material"
                                                  titulobtn="AGENDAR PAGAMENTO"
                                                />
                                              </>
                                            ) :*/
                                        // <>
                                        //   <BtnCartaoMD
                                        //     idboleto={itemMaterial.IDBOLETO}
                                        //     idlan={itemMaterial.IDLAN}
                                        //     tipoparcelamento="MD"
                                        //   />
                                        //   <br />
                                        // </>

                                        itemMaterial.BTN_STATUS ===
                                          'Imprimir' ? (
                                          <>
                                            <BtnBoleto
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              ipte={itemMaterial.IPTE}
                                              codbarras={
                                                itemMaterial.CODIGOBARRAS
                                              }
                                              tipo="material"
                                            />
                                            <br />
                                            <BtnCartao
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              idlan={itemMaterial.IDLAN}
                                              tipoparcelamento="MD"
                                              statusAg={
                                                itemMaterial.AGENDAMENTOSTATUS
                                              }
                                            />
                                            <br />
                                            {dadosparamsmd.PAGAGENDADOMD ===
                                              'S' &&
                                            itemMaterial.AGENDAMENTOSTATUS ==
                                              'CANCELADO' ? (
                                              <BtnAgendamento
                                                idboleto={itemMaterial.IDBOLETO}
                                                idlan={itemMaterial.IDLAN}
                                                status={
                                                  itemMaterial.AGENDAMENTOSTATUS
                                                }
                                                tipoparcelamento="MD"
                                                tipo="material"
                                                titulobtn="AGENDAR PAGAMENTO"
                                              />
                                            ) : null}
                                          </>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'NExibir' ? (
                                          <></>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'Pago' ? (
                                          <>
                                            <a
                                              style={{
                                                backgroundColor: primaryColor,
                                                color: '#fff',
                                              }}
                                              className={`btn btn-${primaryColor} btn-block`}
                                            >
                                              PAGO
                                            </a>
                                          </>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'Solicitar' ? (
                                          <>
                                            <SolicitarBoletos
                                              statucnab={itemMaterial.statucnab}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              idboleto={itemMaterial.IDBOLETO}
                                              idlan={itemMaterial.IDLAN}
                                            />
                                            <br />
                                            <BtnCartao
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              tipoparcelamento="MD"
                                              statusAg={
                                                itemMaterial.AGENDAMENTOSTATUS
                                              }
                                            />
                                            {
                                              <BtnPix
                                                ra={itemMaterial.RA}
                                                codcoligada={
                                                  itemMaterial.CODCOLIGADA
                                                }
                                                codfilial={
                                                  itemMaterial.CODFILIAL
                                                }
                                                codperlet={
                                                  itemMaterial.CODPERLET
                                                }
                                                codturma={itemMaterial.CODTURMA}
                                                idboleto={itemMaterial.IDBOLETO}
                                                tipoparcelamento="MD"
                                              />
                                            }
                                            {dadosparamsmd.PAGAGENDADOMD ===
                                              'S' &&
                                            itemMaterial.AGENDAMENTOSTATUS ==
                                              'CANCELADO' ? (
                                              <BtnAgendamento
                                                idboleto={itemMaterial.IDBOLETO}
                                                idlan={itemMaterial.IDLAN}
                                                status={
                                                  itemMaterial.AGENDAMENTOSTATUS
                                                }
                                                tipoparcelamento="MD"
                                                tipo="material"
                                                titulobtn="AGENDAR PAGAMENTO"
                                              />
                                            ) : null}
                                          </>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'BoletonaoRegistrado' ? (
                                          <>
                                            {
                                              // Habilitado somente para o Banco do Brasil
                                              itemMaterial.NUMBANCO ===
                                              '001' ? (
                                                <SolicitarBoletos
                                                  codcoligada={
                                                    itemMaterial.CODCOLIGADA
                                                  }
                                                  statucnab={
                                                    itemMaterial.CNABSTATUS
                                                  }
                                                  idboleto={
                                                    itemMaterial.IDBOLETO
                                                  }
                                                  idlan={itemMaterial.IDLAN}
                                                />
                                              ) : (
                                                <>
                                                  <BtnBoleto
                                                    idboleto={
                                                      itemMaterial.IDBOLETO
                                                    }
                                                    ra={itemMaterial.RA}
                                                    codcoligada={
                                                      itemMaterial.CODCOLIGADA
                                                    }
                                                    codfilial={
                                                      itemMaterial.CODFILIAL
                                                    }
                                                    codperlet={
                                                      itemMaterial.CODPERLET
                                                    }
                                                    codturma={
                                                      itemMaterial.CODTURMA
                                                    }
                                                    ipte={itemMaterial.IPTE}
                                                    codbarras={
                                                      itemMaterial.CODIGOBARRAS
                                                    }
                                                    tipo="material"
                                                    acao="solicitar"
                                                  />
                                                </>
                                              )
                                            }
                                            <br />
                                            <BtnCartao
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              tipoparcelamento="MD"
                                              statusAg={
                                                itemMaterial.AGENDAMENTOSTATUS
                                              }
                                            />
                                          </>
                                        ) : null
                                      ) : itemMaterial.BTN_STATUS ===
                                        'Imprimir' ? (
                                        <>
                                          <BtnBoleto
                                            ra={itemMaterial.RA}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            codfilial={itemMaterial.CODFILIAL}
                                            codperlet={itemMaterial.CODPERLET}
                                            codturma={itemMaterial.CODTURMA}
                                            idboleto={itemMaterial.IDBOLETO}
                                            ipte={itemMaterial.IPTE}
                                            codbarras={
                                              itemMaterial.CODIGOBARRAS
                                            }
                                            tipo="material"
                                          />
                                          <br />
                                          <BtnCartao
                                            ra={itemMaterial.RA}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            codfilial={itemMaterial.CODFILIAL}
                                            codperlet={itemMaterial.CODPERLET}
                                            codturma={itemMaterial.CODTURMA}
                                            idboleto={itemMaterial.IDBOLETO}
                                            idlan={itemMaterial.IDLAN}
                                            tipoparcelamento="MD"
                                            statusAg={
                                              itemMaterial.AGENDAMENTOSTATUS
                                            }
                                          />
                                          {
                                            <BtnPix
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              tipoparcelamento="MD"
                                            />
                                          }
                                        </>
                                      ) : itemMaterial.BTN_STATUS ===
                                        'NExibir' ? (
                                        <></>
                                      ) : itemMaterial.BTN_STATUS === 'Pago' ? (
                                        <>
                                          <a
                                            style={{
                                              backgroundColor: primaryColor,
                                              color: '#fff',
                                            }}
                                            className={`btn btn-${primaryColor} btn-block`}
                                          >
                                            PAGO
                                          </a>
                                        </>
                                      ) : itemMaterial.BTN_STATUS ===
                                        'Solicitar' ? (
                                        <>
                                          <SolicitarBoletos
                                            statucnab={itemMaterial.statucnab}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            idboleto={itemMaterial.IDBOLETO}
                                            idlan={itemMaterial.IDLAN}
                                          />
                                          <br />
                                          <BtnCartao
                                            ra={itemMaterial.RA}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            codfilial={itemMaterial.CODFILIAL}
                                            codperlet={itemMaterial.CODPERLET}
                                            codturma={itemMaterial.CODTURMA}
                                            idboleto={itemMaterial.IDBOLETO}
                                            tipoparcelamento="MD"
                                            statusAg={
                                              itemMaterial.AGENDAMENTOSTATUS
                                            }
                                          />
                                          {
                                            <BtnPix
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              tipoparcelamento="MD"
                                            />
                                          }
                                        </>
                                      ) : itemMaterial.BTN_STATUS ===
                                        'BoletonaoRegistrado' ? (
                                        <>
                                          {
                                            // Habilitado somente para o Banco do Brasil
                                            // itemMaterial.NUMBANCO === "001" ? (
                                            //   <SolicitarBoletos
                                            //     codcoligada={itemMaterial.CODCOLIGADA}
                                            //     statucnab={itemMaterial.CNABSTATUS}
                                            //     idboleto={itemMaterial.IDBOLETO}
                                            //     idlan={itemMaterial.IDLAN}
                                            //   />
                                            // ) : (
                                            //   <BtnBoleto
                                            //     idboleto={itemMaterial.IDBOLETO}
                                            //     ra={itemMaterial.RA}
                                            //     codcoligada={itemMaterial.CODCOLIGADA}
                                            //     codfilial={itemMaterial.CODFILIAL}
                                            //     codperlet={itemMaterial.CODPERLET}
                                            //     codturma={itemMaterial.CODTURMA}
                                            //     ipte={itemMaterial.IPTE}
                                            //     codbarras={itemMaterial.CODIGOBARRAS}
                                            //     tipo="material"
                                            //     acao="solicitar"
                                            //   />
                                            // )
                                          }
                                          <br />
                                          <BtnCartao
                                            ra={itemMaterial.RA}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            codfilial={itemMaterial.CODFILIAL}
                                            codperlet={itemMaterial.CODPERLET}
                                            codturma={itemMaterial.CODTURMA}
                                            idboleto={itemMaterial.IDBOLETO}
                                            tipoparcelamento="MD"
                                            statusAg={
                                              itemMaterial.AGENDAMENTOSTATUS
                                            }
                                          />
                                        </>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <>
                              {dadosmaterial.length === 0 ? (
                                // <TableSkeleton />
                                <tr>
                                  <td
                                    colSpan="6"
                                    style={{ textAlign: 'center' }}
                                  >
                                    <CardSemDados />
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td
                                    colSpan="6"
                                    style={{ textAlign: 'center' }}
                                  >
                                    <CardSemDados />
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <CardBody>
                        {dadosmaterial.length > 0 ? (
                          dadosmaterial.map((itemMaterial, index) => {
                            var hj = new Date()
                            var vencto = new Date(itemMaterial.DTVENCTO) // já está no formato "DD/MM/YYYY"
                            var statusbol = ''
                            var statucnab = itemMaterial.CNABSTATUS

                            switch (statucnab) {
                              case 0:
                                statusbol = 'Não Remetido'
                                break
                              case 1:
                                statusbol = 'Remetido'
                                break
                              case 2:
                                statusbol = 'Registrado'
                                break
                              case 3:
                                statusbol = 'Recusado'
                                break
                              case 4:
                                statusbol = 'Baixado'
                                break
                              case 5:
                                statusbol = 'Registrado Online'
                                break
                              case 6:
                                statusbol = 'Cancelado'
                                break
                              case 7:
                                statusbol = 'Pendente Remessa'
                                break
                              default:
                                console.log('default')
                            }
                            return (
                              <Row className="icon-examples">
                                <Col class="col-12">
                                  <div
                                    style={{
                                      backgroundColor: '#f5f5f5',
                                      borderRadius: 5,
                                      color: '#32325d',
                                      fontSize: 18,
                                      margin: 0,
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: '#ebeced',
                                        height: '28px',
                                      }}
                                    >
                                      <span
                                        style={{
                                          float: 'left',
                                          color: '#707070',
                                          textAlign: 'left',
                                          fontSize: 14,
                                          marginTop: 3,
                                          marginLeft: 10,
                                        }}
                                      >
                                        Vencimento: {itemMaterial.VENCIMENTO}
                                      </span>
                                      <span
                                        style={{
                                          float: 'right',
                                          color: '#707070',
                                          textAlign: 'left',
                                          fontSize: 12,
                                        }}
                                      >
                                        <Badge
                                          color=""
                                          className="badge-dot mr-4"
                                        >
                                          {dadosparamsmd !== undefined ? (
                                            dadosparamsmd.PAGAGENDADOMD ===
                                            'S' ? (
                                              itemMaterial.AGENDAMENTOSTATUS ==
                                              'AGENDADO' ? (
                                                <>
                                                  <i className="bg-success" />
                                                  Agendado
                                                </>
                                              ) : itemMaterial.AGENDAMENTOSTATUS ===
                                                'PREAGENDADO' ? (
                                                <>
                                                  <i className="bg-success" />
                                                  Pré-Agendado
                                                </>
                                              ) : itemMaterial.BTN_STATUS ===
                                                'Pago' ? (
                                                <>
                                                  <i className="bg-success" />
                                                  {itemMaterial.LAN_STATUS}
                                                </>
                                              ) : itemMaterial.BTN_STATUS ===
                                                'Imprimir' ? (
                                                <>
                                                  <i className="bg-primary" />
                                                  {itemMaterial.LAN_STATUS}
                                                </>
                                              ) : itemMaterial.BTN_STATUS ===
                                                'Solicitar' ? (
                                                <>
                                                  <i className="bg-primary" />
                                                  Indisponível
                                                </>
                                              ) : itemMaterial.BTN_STATUS ===
                                                'BoletonaoRegistrado' ? (
                                                <>
                                                  <i className="bg-primary" />
                                                  {itemMaterial.LAN_STATUS}
                                                </>
                                              ) : null
                                            ) : itemMaterial.BTN_STATUS ===
                                              'Pago' ? (
                                              <>
                                                <i className="bg-success" />
                                                {itemMaterial.LAN_STATUS}
                                              </>
                                            ) : itemMaterial.BTN_STATUS ===
                                              'Imprimir' ? (
                                              <>
                                                <i className="bg-primary" />
                                                {itemMaterial.LAN_STATUS}
                                              </>
                                            ) : itemMaterial.BTN_STATUS ===
                                              'Solicitar' ? (
                                              <>
                                                <i className="bg-primary" />
                                                Indisponível
                                              </>
                                            ) : itemMaterial.BTN_STATUS ===
                                              'BoletonaoRegistrado' ? (
                                              <>
                                                <i className="bg-primary" />
                                                {itemMaterial.LAN_STATUS}
                                              </>
                                            ) : null
                                          ) : itemMaterial.BTN_STATUS ===
                                            'Pago' ? (
                                            <>
                                              <i className="bg-success" />
                                              {itemMaterial.LAN_STATUS}
                                            </>
                                          ) : itemMaterial.BTN_STATUS ===
                                            'Imprimir' ? (
                                            <>
                                              <i className="bg-primary" />
                                              {itemMaterial.LAN_STATUS}
                                            </>
                                          ) : itemMaterial.BTN_STATUS ===
                                            'Solicitar' ? (
                                            <>
                                              <i className="bg-primary" />
                                              Indisponível
                                            </>
                                          ) : itemMaterial.BTN_STATUS ===
                                            'BoletonaoRegistrado' ? (
                                            <>
                                              <i className="bg-primary" />
                                              {itemMaterial.LAN_STATUS}
                                            </>
                                          ) : null}
                                        </Badge>
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        marginLeft: 10,
                                        marginTop: 5,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      <a className="avatar rounded-circle mr-3">
                                        {itemMaterial.STATUS === 1 ? (
                                          <img
                                            alt="..."
                                            src={
                                              require('../assets/img/theme/pago.jpg')
                                                .default
                                            }
                                          />
                                        ) : vencto < hj ? (
                                          itemMaterial.CNABSTATUS === 2 ? (
                                            <img
                                              alt="..."
                                              src={
                                                require('../assets/img/theme/vencido.jpg')
                                                  .default
                                              }
                                            />
                                          ) : (
                                            <img
                                              alt="..."
                                              src={
                                                require('../assets/img/theme/indiponivel.jpg')
                                                  .default
                                              }
                                            />
                                          )
                                        ) : (
                                          <img
                                            alt="..."
                                            src={
                                              require('../assets/img/theme/imprimir.jpg')
                                                .default
                                            }
                                          />
                                        )}
                                      </a>
                                      <span
                                        style={{
                                          fontSize: 14,
                                          marginTop: 10,
                                          color: '#000',
                                        }}
                                      >
                                        <span className="mb-0 text-sm">
                                          {itemMaterial.QTDLANCAMENTOS > 1 ? (
                                            <>
                                              {' '}
                                              <span>
                                                {itemMaterial.VALORLIQ}
                                              </span>{' '}
                                              - Material didático{' '}
                                            </>
                                          ) : (
                                            <>
                                              {' '}
                                              <span>
                                                {itemMaterial.VALORLIQ}
                                              </span>{' '}
                                              - Material didático{' '}
                                            </>
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: 10,
                                        marginRight: 10,
                                        marginBottom: 10,
                                        marginTop: 10,
                                      }}
                                      className="align-items-center"
                                    >
                                      {dadosparamsmd !== undefined &&
                                      dadosparamsmd.PAGAGENDADOMD === 'S' ? (
                                        itemMaterial.AGENDAMENTOSTATUS ==
                                        'AGENDADO' ? (
                                          <>
                                            <BtnAgendamento
                                              idboleto={itemMaterial.IDBOLETO}
                                              idlan={itemMaterial.IDLAN}
                                              status={
                                                itemMaterial.AGENDAMENTOSTATUS
                                              }
                                              tipoparcelamento="MD"
                                              tipo="material"
                                              // titulobtn="PGTO. AGENDADO"
                                              titulobtn="CANCELAR AGENDAMENTO"
                                            />
                                            <br />
                                          </>
                                        ) : itemMaterial.AGENDAMENTOSTATUS ===
                                          'PREAGENDADO' ? (
                                          <>
                                            <BtnCartaoMD
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              idlan={itemMaterial.IDLAN}
                                              tipoparcelamento="MD"
                                              agendamentostatus="PREAGENDADO"
                                            />
                                          </>
                                        ) : /*itemMaterial.AGENDAMENTOSTATUS ==
                                              "CONCLUIDO" ||
                                              itemMaterial.AGENDAMENTOSTATUS ==
                                              "CANCELADO" ? (
                                              <>
                                                <BtnAgendamento
                                                  idboleto={itemMaterial.IDBOLETO}
                                                  idlan={itemMaterial.IDLAN}
                                                  status={
                                                    itemMaterial.AGENDAMENTOSTATUS
                                                  }
                                                  tipoparcelamento="MD"
                                                  tipo="material"
                                                  titulobtn="AGENDAR PAGAMENTO"
                                                />
                                              </>
                                            ) :*/
                                        // <>
                                        //   <BtnCartaoMD
                                        //     idboleto={itemMaterial.IDBOLETO}
                                        //     idlan={itemMaterial.IDLAN}
                                        //     tipoparcelamento="MD"
                                        //   />
                                        //   <br />
                                        // </>

                                        itemMaterial.BTN_STATUS ===
                                          'Imprimir' ? (
                                          <>
                                            <BtnBoleto
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              ipte={itemMaterial.IPTE}
                                              codbarras={
                                                itemMaterial.CODIGOBARRAS
                                              }
                                              tipo="material"
                                            />
                                            <br />
                                            <BtnCartao
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              idlan={itemMaterial.IDLAN}
                                              tipoparcelamento="MD"
                                              statusAg={
                                                itemMaterial.AGENDAMENTOSTATUS
                                              }
                                            />
                                            <br />
                                            {dadosparamsmd.PAGAGENDADOMD ===
                                              'S' &&
                                            itemMaterial.AGENDAMENTOSTATUS ==
                                              'CANCELADO' ? (
                                              <BtnAgendamento
                                                idboleto={itemMaterial.IDBOLETO}
                                                idlan={itemMaterial.IDLAN}
                                                status={
                                                  itemMaterial.AGENDAMENTOSTATUS
                                                }
                                                tipoparcelamento="MD"
                                                tipo="material"
                                                titulobtn="AGENDAR PAGAMENTO"
                                              />
                                            ) : null}
                                          </>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'NExibir' ? (
                                          <></>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'Pago' ? (
                                          <>
                                            <a
                                              style={{
                                                backgroundColor: primaryColor,
                                                color: '#fff',
                                              }}
                                              className={`btn btn-${primaryColor} btn-block`}
                                            >
                                              PAGO
                                            </a>
                                          </>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'Solicitar' ? (
                                          <>
                                            <SolicitarBoletos
                                              statucnab={itemMaterial.statucnab}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              idboleto={itemMaterial.IDBOLETO}
                                              idlan={itemMaterial.IDLAN}
                                            />
                                            <br />
                                            <BtnCartao
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              tipoparcelamento="MD"
                                              statusAg={
                                                itemMaterial.AGENDAMENTOSTATUS
                                              }
                                            />
                                            {
                                              <BtnPix
                                                ra={itemMaterial.RA}
                                                codcoligada={
                                                  itemMaterial.CODCOLIGADA
                                                }
                                                codfilial={
                                                  itemMaterial.CODFILIAL
                                                }
                                                codperlet={
                                                  itemMaterial.CODPERLET
                                                }
                                                codturma={itemMaterial.CODTURMA}
                                                idboleto={itemMaterial.IDBOLETO}
                                                tipoparcelamento="MD"
                                              />
                                            }
                                            {dadosparamsmd.PAGAGENDADOMD ===
                                              'S' &&
                                            itemMaterial.AGENDAMENTOSTATUS ==
                                              'CANCELADO' ? (
                                              <BtnAgendamento
                                                idboleto={itemMaterial.IDBOLETO}
                                                idlan={itemMaterial.IDLAN}
                                                status={
                                                  itemMaterial.AGENDAMENTOSTATUS
                                                }
                                                tipoparcelamento="MD"
                                                tipo="material"
                                                titulobtn="AGENDAR PAGAMENTO"
                                              />
                                            ) : null}
                                          </>
                                        ) : itemMaterial.BTN_STATUS ===
                                          'BoletonaoRegistrado' ? (
                                          <>
                                            {
                                              // Habilitado somente para o Banco do Brasil
                                              // itemMaterial.NUMBANCO === "001" ? (
                                              //   <SolicitarBoletos
                                              //     codcoligada={itemMaterial.CODCOLIGADA}
                                              //     statucnab={itemMaterial.CNABSTATUS}
                                              //     idboleto={itemMaterial.IDBOLETO}
                                              //     idlan={itemMaterial.IDLAN}
                                              //   />
                                              // ) : (
                                              //   <BtnBoleto
                                              //     idboleto={itemMaterial.IDBOLETO}
                                              //     ra={itemMaterial.RA}
                                              //     codcoligada={itemMaterial.CODCOLIGADA}
                                              //     codfilial={itemMaterial.CODFILIAL}
                                              //     codperlet={itemMaterial.CODPERLET}
                                              //     codturma={itemMaterial.CODTURMA}
                                              //     ipte={itemMaterial.IPTE}
                                              //     codbarras={itemMaterial.CODIGOBARRAS}
                                              //     tipo="material"
                                              //     acao="solicitar"
                                              //   />
                                              // )
                                            }
                                            <br />
                                            <BtnCartao
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              tipoparcelamento="MD"
                                              statusAg={
                                                itemMaterial.AGENDAMENTOSTATUS
                                              }
                                            />
                                          </>
                                        ) : null
                                      ) : itemMaterial.BTN_STATUS ===
                                        'Imprimir' ? (
                                        <>
                                          <BtnBoleto
                                            ra={itemMaterial.RA}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            codfilial={itemMaterial.CODFILIAL}
                                            codperlet={itemMaterial.CODPERLET}
                                            codturma={itemMaterial.CODTURMA}
                                            idboleto={itemMaterial.IDBOLETO}
                                            ipte={itemMaterial.IPTE}
                                            codbarras={
                                              itemMaterial.CODIGOBARRAS
                                            }
                                            tipo="material"
                                          />
                                          <br />
                                          <BtnCartao
                                            ra={itemMaterial.RA}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            codfilial={itemMaterial.CODFILIAL}
                                            codperlet={itemMaterial.CODPERLET}
                                            codturma={itemMaterial.CODTURMA}
                                            idboleto={itemMaterial.IDBOLETO}
                                            idlan={itemMaterial.IDLAN}
                                            tipoparcelamento="MD"
                                            statusAg={
                                              itemMaterial.AGENDAMENTOSTATUS
                                            }
                                          />
                                          {
                                            <BtnPix
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              tipoparcelamento="MD"
                                            />
                                          }
                                        </>
                                      ) : itemMaterial.BTN_STATUS ===
                                        'NExibir' ? (
                                        <></>
                                      ) : itemMaterial.BTN_STATUS === 'Pago' ? (
                                        <>
                                          <a
                                            style={{
                                              backgroundColor: primaryColor,
                                              color: '#fff',
                                            }}
                                            className={`btn btn-${primaryColor} btn-block`}
                                          >
                                            PAGO
                                          </a>
                                        </>
                                      ) : itemMaterial.BTN_STATUS ===
                                        'Solicitar' ? (
                                        <>
                                          <SolicitarBoletos
                                            statucnab={itemMaterial.statucnab}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            idboleto={itemMaterial.IDBOLETO}
                                            idlan={itemMaterial.IDLAN}
                                          />
                                          <br />
                                          <BtnCartao
                                            ra={itemMaterial.RA}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            codfilial={itemMaterial.CODFILIAL}
                                            codperlet={itemMaterial.CODPERLET}
                                            codturma={itemMaterial.CODTURMA}
                                            idboleto={itemMaterial.IDBOLETO}
                                            tipoparcelamento="MD"
                                            statusAg={
                                              itemMaterial.AGENDAMENTOSTATUS
                                            }
                                          />
                                          {
                                            <BtnPix
                                              ra={itemMaterial.RA}
                                              codcoligada={
                                                itemMaterial.CODCOLIGADA
                                              }
                                              codfilial={itemMaterial.CODFILIAL}
                                              codperlet={itemMaterial.CODPERLET}
                                              codturma={itemMaterial.CODTURMA}
                                              idboleto={itemMaterial.IDBOLETO}
                                              tipoparcelamento="MD"
                                            />
                                          }
                                        </>
                                      ) : itemMaterial.BTN_STATUS ===
                                        'BoletonaoRegistrado' ? (
                                        <>
                                          {
                                            // Habilitado somente para o Banco do Brasil
                                            // itemMaterial.NUMBANCO === "001" ? (
                                            //   <SolicitarBoletos
                                            //     codcoligada={itemMaterial.CODCOLIGADA}
                                            //     statucnab={itemMaterial.CNABSTATUS}
                                            //     idboleto={itemMaterial.IDBOLETO}
                                            //     idlan={itemMaterial.IDLAN}
                                            //   />
                                            // ) : (
                                            //   <BtnBoleto
                                            //     idboleto={itemMaterial.IDBOLETO}
                                            //     ra={itemMaterial.RA}
                                            //     codcoligada={itemMaterial.CODCOLIGADA}
                                            //     codfilial={itemMaterial.CODFILIAL}
                                            //     codperlet={itemMaterial.CODPERLET}
                                            //     codturma={itemMaterial.CODTURMA}
                                            //     ipte={itemMaterial.IPTE}
                                            //     codbarras={itemMaterial.CODIGOBARRAS}
                                            //     tipo="material"
                                            //     acao="solicitar"
                                            //   />
                                            // )
                                          }
                                          <br />
                                          <BtnCartao
                                            ra={itemMaterial.RA}
                                            codcoligada={
                                              itemMaterial.CODCOLIGADA
                                            }
                                            codfilial={itemMaterial.CODFILIAL}
                                            codperlet={itemMaterial.CODPERLET}
                                            codturma={itemMaterial.CODTURMA}
                                            idboleto={itemMaterial.IDBOLETO}
                                            tipoparcelamento="MD"
                                            statusAg={
                                              itemMaterial.AGENDAMENTOSTATUS
                                            }
                                          />
                                        </>
                                      ) : null}
                                    </div>
                                    <div
                                      style={{
                                        height: 5,
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            )
                          })
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {dadosmensalidades.length === 0 ? (
                              <span>
                                <Loader
                                  type="ThreeDots"
                                  color="#9e9e9e"
                                  height={50}
                                  width={50}
                                  // timeout={3000} //3 secs
                                />
                              </span>
                            ) : (
                              <span>
                                <CardSemDados />
                              </span>
                            )}
                          </div>
                        )}
                      </CardBody>
                    )}
                    <CardFooter className="py-4"></CardFooter>
                  </Card>
                </div>
              </Row>
            </div>
          </CollapseTurmas>
        </div>
      )
    })
  }

  const listarMensalidades = async (ra, coligada, codPerlet, codTurma) => {
    setload(true)
    const myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=h6jqsvm0pc9h7rca5k49u0gesdfs3b0b')

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    try {
      const response = await fetch(
        `${api}/listamensalidadesra?ra=${ra}&codperlet=${codPerlet}`,
        requestOptions
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const result = await response.json()
      setdadosMensalidades(result.dados)
      setTotmensalidades(result.record_count)
      // return result; // Retornando o resultado
      setload(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setdadosMensalidades([])
      setTotmensalidades([])
      setload(false)
    }
  }

  const listarTotaisMensalidadesAbertasPagas = async (
    ra,
    coligada,
    codPerlet,
    codTurma
  ) => {
    const myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=h6jqsvm0pc9h7rca5k49u0gesdfs3b0b')

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    try {
      const response = await fetch(
        `${api}/totaldemensalidadesabertaspagas?ra=${ra}&coligada=${coligada}&codperlet=${codPerlet}&codturma=${codTurma}`,
        requestOptions
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const result = await response.json()
      setTotmensalidadesabertas(result.dados)
      // return result; // Retornando o resultado
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const Totalizadores = (props) => {
    const dados = props.data
    const { totalAbertas, totalPagas } = dados

    const boletosPorTurma = {}

    // totalAbertas.forEach(item => {
    //   const { CODTURMA, QTDBOLETOS } = item;
    //   boletosPorTurma[CODTURMA] = {
    //     abertas: QTDBOLETOS,
    //     pagas: 0
    //   };
    // });

    // totalPagas.forEach(item => {
    //   const { CODTURMA, QTDBOLETOS } = item;
    //   if (boletosPorTurma[CODTURMA]) {
    //     boletosPorTurma[CODTURMA].pagas = QTDBOLETOS;
    //   } else {
    //     boletosPorTurma[CODTURMA] = {
    //       abertas: 0,
    //       pagas: QTDBOLETOS
    //     };
    //   }
    // });

    return (
      <div>
        {/* <h2>Quantidade de Boletos por Turma:</h2>
      <ul>
        {Object.keys(boletosPorTurma).map(codTurma => (
          <li key={codTurma}>
            Turma: {codTurma} - Abertas: {boletosPorTurma[codTurma].abertas} - Pagas: {boletosPorTurma[codTurma].pagas}
          </li>
        ))}
      </ul> */}
      </div>
    )
  }

  const TableSkeleton = () => {
    const linhas = [1, 2, 3, 4, 5]
    return (
      <>
        {linhas.map((number) => (
          <tr key={number}>
            <th scope="row">
              <>
                <Skeleton circle={true} width={42} height={42} />
              </>
            </th>
            <td>
              <Skeleton height={20} />
            </td>
            <td>
              <Skeleton height={20} />
            </td>
            <td>
              <Skeleton height={20} />
            </td>
            <td>
              <Skeleton height={20} />
            </td>
            <td>
              <Skeleton height={20} width={180} />
            </td>
          </tr>
        ))}
      </>
    )
  }

  async function getCodEditora(coligada, filial, ano) {
    let editora = await fetch(
      `${api}/geteditora?coligada=${coligada}&ano=${ano}&filial=${filial}`
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.record_count > 0) {
          return result.dados[0].CODEDITORA
        } else {
          return 16
        }
      })
      .catch((error) => console.log('error', error))

    return editora
  }

  async function getEnotas(coligada, filial) {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    var raw = JSON.stringify({
      codcoligada: coligada,
      codfilial: filial,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    let idempresa = await fetch(`${api}/dadosenotasfilial`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status} ${response.statusText}`)
        }
        return response.json()
      })
      .then((data) => {
        // Fazer algo com os dados da resposta da API
        // console.log('Dados do eNotas: ', data);
        // console.log('Dados do eNotas: ', data.dados[0].IDEMPRESAENOTAS);
        if (data.record_count > 0) {
          return data.dados[0].IDEMPRESAENOTAS
        } else {
          return 'NAO_INTEGRADA'
        }
      })
      .catch((error) => {
        // console.error('Erro na requisição:', error);
        return error
      })

    return idempresa
  }

  const Diversos = (props) => {
    const [servicos, setServicos] = useState([])
    const [nmservicos, setNmServicos] = useState('')
    const [listaServicosCarregada, setListaServicosCarregada] = useState(false)

    useEffect(() => {
      if (!listaServicosCarregada) {
        const myHeaders = new Headers()
        myHeaders.append(
          'Cookie',
          'ci_session=qa9suqr0d45r0d0nbb5fjc2etje963st'
        )

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }

        fetch(
          `${api}/listaservicosboleto?ra=${localStorage.getItem(
            '@meuboleto/ra'
          )}&coligada=${localStorage.getItem(
            '@meuboleto-app/coligada'
          )}&idboleto=${props.idboleto}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setNmServicos(result.dados[0].NOME)
            setServicos(result.dados)
            setListaServicosCarregada(true)
            abrir()
          })
          .catch((error) => console.log('error', error))
      }
    }, [props.idboleto, listaServicosCarregada])

    return (
      <>
        <span>
          {servicos.length > 0 ? (
            servicos.map((servico, index) => (
              <span key={index} className="mb-0 text-sm">
                {servico.NOME}
                <br />
              </span>
            ))
          ) : (
            <span style={{ color: '#000' }}>Não existem serviços.</span>
          )}
        </span>
      </>
    )
  }

  // const Diversos = (props) => {
  //   useEffect(() => {

  //     var myHeaders = new Headers();
  //     myHeaders.append(
  //       "Cookie",
  //       "ci_session=qa9suqr0d45r0d0nbb5fjc2etje963st"
  //     );

  //     var requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };

  //     fetch(
  //       `${api}/listaservicosboleto?ra=${localStorage.getItem(
  //         "@meuboleto/ra"
  //       )}&coligada=${localStorage.getItem(
  //         "@meuboleto-app/coligada"
  //       )}&idboleto=${props.idboleto}`,
  //       requestOptions
  //     )
  //       .then((response) => response.json())
  //       .then((result) => {
  //         setnmservicos(result.dados[0].NOME);
  //         setservicos(result.dados);
  //         abrir();
  //       })
  //       .catch((error) => console.log("error", error));
  //   }, [props.idboleto])

  //   return (
  //     <>
  //       <span
  //         style={{ cursor: "pointer" }}
  //         // onClick={() => {
  //         //   var myHeaders = new Headers();
  //         //   myHeaders.append(
  //         //     "Cookie",
  //         //     "ci_session=qa9suqr0d45r0d0nbb5fjc2etje963st"
  //         //   );

  //         //   var requestOptions = {
  //         //     method: "GET",
  //         //     headers: myHeaders,
  //         //     redirect: "follow",
  //         //   };

  //         //   fetch(
  //         //     `${api}/listaservicosboleto?ra=${localStorage.getItem(
  //         //       "@meuboleto/ra"
  //         //     )}&coligada=${localStorage.getItem(
  //         //       "@meuboleto-app/coligada"
  //         //     )}&idboleto=${props.idboleto}`,
  //         //     requestOptions
  //         //   )
  //         //     .then((response) => response.json())
  //         //     .then((result) => {
  //         //       setnmservicos(result.dados[0].NOME);
  //         //       setservicos(result.dados);
  //         //       abrir();
  //         //     })
  //         //     .catch((error) => console.log("error", error));
  //         // }}
  //       >
  //         {/* Lista de serviços{" "} */}
  //         {servicos.length > 0 ? (
  //           servicos.map((servico, Index) => (
  //             <>
  //               {/* <span style={{ color: "#000" }}>- {servico.NOME}</span> */}
  //               <span className="mb-0 text-sm">{servico.NOME}</span>
  //               <br />
  //             </>
  //           ))
  //         ) : (
  //           <span style={{ color: "#000" }}>
  //             Não existem servicos.
  //           </span>
  //         )}
  //       </span>

  //     </>
  //   );
  // };

  const BtnCartao = (props) => {
    function onClickCartao() {
      if (props.tipoparcelamento === 'MD') {
        setloadqtdparc(true)
        fetch(
          `${api_matriculei}/api/MatriculaMdParcela/ObterInformacoesMd/${localStorage.getItem(
            '@meuboleto-app/editora'
          )}/${props.idboleto}/${user.ra}`
        )
          .then((response) => response.json())
          .then((result) => {
            setloadqtdparc(false)
            // console.log(result);
            // for (var i = 1; i < result.QtdParcelas+1; i++) {
            //   qtdparcelascartao.push(i);
            //  }

            if (result.RA !== undefined) {
              if (props.statusAg == 'PREAGENDADO') {
                setqtdparcelascartao({
                  RA: user.ra,
                  QtdParcelas: 1,
                  Status: false,
                })
              } else if (props.statusAg == 'CANCELADO') {
                setqtdparcelascartao({
                  RA: user.ra,
                  QtdParcelas: 1,
                  Status: false,
                })
              } else {
                setqtdparcelascartao(result)
              }
            } else {
              setqtdparcelascartao({
                RA: user.ra,
                QtdParcelas: 1,
                Status: false,
              })
            }
          })
          .catch((error) => {
            setloadqtdparc(false)
            // console.log("error", error)
            setqtdparcelascartao({
              RA: user.ra,
              QtdParcelas: 1,
              Status: false,
            })
          })

        // Add o evento no GA
        ReactGA.event({
          category: 'BtnCartao',
          action: 'PgtoCartao',
          label: `${api_matriculei}/api/MatriculaMdParcela/ObterInformacoesMd/${localStorage.getItem(
            '@meuboleto-app/editora'
          )}/${props.idboleto}/${props.ra}`,
        })
      } else {
        setloadqtdparc(true)
        fetch(
          `${api_matriculei}/api/MatriculaContratoParcela/ObterInformacoesMensalidade/${props.codcoligada}/${props.idboleto}/${props.ra}`
        )
          .then((response) => response.json())
          .then((result) => {
            setloadqtdparc(false)
            result.RA !== undefined
              ? setqtdparcelascartao(result)
              : setqtdparcelascartao({
                  RA: user.ra,
                  QtdParcelas: 1,
                  Status: false,
                })

            // Add o evento no GA
            ReactGA.event({
              category: 'BtnCartao',
              action: 'PgtoCartao',
              label: `${api_matriculei}/api/MatriculaContratoParcela/ObterInformacoesMensalidade/${props.codcoligada}/${props.idboleto}/${props.ra}`,
            })
          })
          .catch((error) => {
            setloadqtdparc(false)
            //console.log('error', error);
            console.log(
              'error ao obter ObterInformacoesMensalidade',
              `${api_matriculei}/api/MatriculaContratoParcela/ObterInformacoesMensalidade/${props.codcoligada}/${props.idboleto}/${props.ra}`
            )
            setqtdparcelascartao({
              RA: user.ra,
              QtdParcelas: 1,
              Status: false,
            })
          })
      }
      setModal(!modal)
      setValidboleto(props.idboleto)
      setRa(props.ra)
      setCodcoligada(props.codcoligada)
      setCodFilial(props.codfilial)
      setcodperlet(props.codperlet)
      settipoparcelamento(props.tipoparcelamento)
    }

    return (
      <a
        style={{ backgroundColor: primaryColor, color: '#fff' }}
        className={`btn btn-${primaryColor} btn-block`}
        onClick={onClickCartao}
      >
        CARTÃO
      </a>
    )
  }

  const BtnAgendamento = (props) => {
    return (
      <>
        <a
          className={`btn btn-warning btn-block`}
          onClick={() => {
            if (props.status === 'AGENDADO') {
              Swal.fire({
                icon: 'warning',
                title: 'Solicitação de cancelamento',
                text: 'Ao optar por cancelar, os agendamentos de todas as parcelas serão cancelados. Você deseja realmente cancelar o agendamento do pagamento recorrente?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Sim, cancelar!',
                denyButtonText: `Não cancelar`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  var myHeaders = new Headers()
                  myHeaders.append('Content-Type', 'application/json')
                  myHeaders.append(
                    'Cookie',
                    'ci_session=fao04j783drqotdf3cplpqcci71l5ajo'
                  )

                  var raw = JSON.stringify({
                    nmaluno: localStorage.getItem('@meuboleto/nome'),
                    ra: localStorage.getItem('@meuboleto/ra'),
                    codcoligada: props.codcoligada,
                  })

                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                  }

                  fetch(`${api}/abrechamadocauagendamento`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                      //console.log(result);
                      if (result.type === 'success') {
                        // Add o evento no GA
                        ReactGA.event({
                          category: 'BtnAgendamento',
                          action: 'CancelamentoAgendamento',
                          label: `Endpoint: ${api}/abrechamadocauagendamento - Payload: ${raw}`,
                        })

                        Swal.fire(
                          '',
                          'Foi realizada a solicitação do cancelamento do pagamento recorrente de seus boletos. O mesmo será realizado em até 5 dias úteis!',
                          'success'
                        )
                      } else {
                        Swal.fire({
                          title: '',
                          html:
                            'Houve um erro na abertoa da solicitação de cancelamento tente mais tarde </br><br/>' +
                            result.errors,
                          icon: 'error',
                          confirmButtonText: 'Sair',
                        })
                      }
                    })
                    .catch((error) => console.log('error', error))
                } else if (result.isDenied) {
                  // Swal.fire('Changes are not saved', '', 'info')
                }
              })
            } else {
              setValidboleto(props.idboleto)
              settipoparcelamento(props.tipoparcelamento)
              setModalagendar(!modalagendar)

              fetch(
                `${api}/listamaterialagendado?ra=${props.ra}&coligada=${
                  props.codcoligada
                }&codturma=${localStorage.getItem(
                  '@meuboleto/turma'
                )}&codperlet=${localStorage.getItem('@meuboleto/perlet')}`
              )
                .then((response) => response.json())
                .then((result) => {
                  // Add o evento no GA
                  ReactGA.event({
                    category: 'BtnAgendamento',
                    action: 'Agendamento',
                    label: `Endpoint: ${api}/listamaterialagendado?ra=${localStorage.getItem(
                      '@meuboleto/ra'
                    )}&coligada=${
                      props.codcoligada
                    }&codturma=${localStorage.getItem(
                      '@meuboleto/turma'
                    )}&codperlet=${localStorage.getItem('@meuboleto/perlet')}`,
                  })

                  //console.log(result.dados);
                  setDadosagendados(result.dados)
                })
                .catch((error) => console.log('error', error))
            }
          }}
        >
          {props.titulobtn}
        </a>
      </>
    )
  }

  const BtnCartaoMD = (props) => {
    const [statuslan, setstatuslan] = useState('')
    var myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=e61eck3713g6ggi7c19oa0tsqo4lqocm')

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(
      `${api}/idlancamentoagendados?idlan=${
        props.idlan
      }&coligada=${localStorage.getItem('@meuboleto-app/coligada')}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setstatuslan(result.dados[0].AGENDAMENTOSTATUS)
      })
      .catch((error) => console.log('error', error))

    return (
      <>
        {statuslan === 'AGENDADO' ? (
          <a className={`btn btn-warning btn-block`}>AGENDADO</a>
        ) : (
          <a
            style={{ backgroundColor: primaryColor, color: '#fff' }}
            className={`btn btn-${primaryColor} btn-block`}
            onClick={() => {
              async function dadosAgendamento() {
                /* ORIGINAL */
                setModalagendar(!modalagendar)
                setIsloadParcAgenda(true)
                //setValidboleto(props.idboleto);
                //settipoparcelamento(props.tipoparcelamento);

                var myHeaders = new Headers()
                myHeaders.append(
                  'Cookie',
                  'ci_session=ppvr57m0uuj42g8t10f947i2tf7j4jup'
                )

                var requestOptions = {
                  method: 'GET',
                  headers: myHeaders,
                  redirect: 'follow',
                }

                const resposta = await fetch(
                  `${api}/listamaterialagendado?ra=${localStorage.getItem(
                    '@meuboleto/ra'
                  )}&coligada=${localStorage.getItem(
                    '@meuboleto-app/editora'
                  )}&codturma=${localStorage.getItem(
                    '@meuboleto/turma'
                  )}&codperlet=${localStorage.getItem('@meuboleto/perlet')}`,
                  requestOptions
                )
                  .then((response) => response.json())
                  .then((result) => {
                    setIsloadParcAgenda(false)
                    setDadosagendados(result.dados)
                  })
                  .catch((error) => {
                    setIsloadParcAgenda(false)
                    console.log('error', error)
                  })
              }

              dadosAgendamento()
            }}
          >
            CARTÃO
          </a>
        )}
      </>
    )
  }

  async function onSubmitParcelas(ev) {
    ev.preventDefault()
    //console.log(qtdparcelas);
    if (qtdparcelas > 0) {
      if (tipoparcelamento === 'MD') {
        var myHeaders = new Headers()
        myHeaders.append(
          'Cookie',
          'ci_session=498ca3b1614do5euu34864ue29edr5es'
        )

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }

        // Aqui eu coloco o de-para das editoras
        var coligada = user.codcoligada
        var filial = user.codfilial
        var periodo = localStorage.getItem('@meuboleto/perlet')

        // console.log('Parametros: ',user.codcoligada, user.codfilial, localStorage.getItem("@meuboleto/perlet"))

        getCodEditora(coligada, filial, periodo)
          .then((editora) => {
            fetch(
              `${api}/idlancamentomaterial?coligada=${editora}&idboleto=${validboleto}`,
              requestOptions
            )
              .then((response) => response.json())
              .then((result) => {
                window.open(
                  `${api_matriculei}/api/Decodificar/CriarPagamentoWebDecodificarComRedirect/${validboleto}/${result.dados[0].IDLAN}/2/3/${editora}/${qtdparcelas}/${qtdparcelas}`,
                  '_blank'
                )
              })
              .catch((error) => console.log('error', error))
          })
          .catch((error) => console.log('error', error))

        // fetch(
        //   `${api}/idlancamentomaterial?coligada=${editora}&idboleto=${validboleto}`,
        //   requestOptions
        // )
        //   .then((response) => response.json())
        //   .then((result) => {
        //     window.open(
        //       `${api_matriculei}/api/Decodificar/CriarPagamentoWebDecodificarComRedirect/${validboleto}/${result.dados[0].IDLAN}/2/3/${editora}/${qtdparcelas}/${qtdparcelas}`,
        //       "_blank"
        //     );
        //   })
        //   .catch((error) => console.log("error", error));
      } else {
        var myHeaders = new Headers()
        myHeaders.append(
          'Cookie',
          'ci_session=498ca3b1614do5euu34864ue29edr5es'
        )

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }

        fetch(
          `${api}/idlancamento?ra=${localStorage.getItem(
            '@meuboleto/ra'
          )}&coligada=${codColigada}&idboleto=${validboleto}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            window.open(
              `${api_matriculei}/api/Decodificar/CriarPagamentoWebDecodificarComRedirect/${validboleto}/${result.dados[0].IDLAN}/2/3/${codColigada}/${qtdparcelas}/${qtdparcelas}`,
              '_blank'
            )
          })
          .catch((error) => console.log('error', error))
      }
    } else {
      Swal.fire(
        'Quantidade de Parcelas',
        'Você deve selecionar a quantidade de parcelas antes de realizar o pagamento.',
        'warning'
      )
    }
  }

  async function onSubmitAgendamento(ev) {
    ev.preventDefault()
    Swal.fire({
      icon: 'warning',
      title: 'Confirmação de agendamento',
      text: 'Ao optar por confirmar, os agendamentos de todas as parcelas listadas serão realizados. Você deseja realmente realizar o pagamento agendado?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sim, agendar!',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsloading(true)

        Swal.fire({
          title: 'Por favor, aguarde!',
          text: ' Este processo pode demorar alguns minutos. Não feche o seu navegador.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        })

        var myHeaders = new Headers()
        myHeaders.append(
          'Cookie',
          'ci_session=68oi8cu76v8a2q2aamqgjn9ctr8igh68'
        )

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }

        fetch(
          `${api}/dadosmaterialagendado?ra=${localStorage.getItem(
            '@meuboleto/ra'
          )}&coligada=${localStorage.getItem(
            '@meuboleto-app/coligada'
          )}&codturma=${localStorage.getItem(
            '@meuboleto/turma'
          )}&codperlet=${localStorage.getItem('@meuboleto/perlet')}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            var myHeaders = new Headers()
            myHeaders.append('Content-Type', 'application/json')

            var raw = JSON.stringify(result.dados)
            //console.log(raw);

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow',
            }

            fetch(
              `${api_matriculei}/api/Decodificar/CriarPagamentoRecorrenteWebDecodificar`,
              requestOptions
            )
              .then((response) => response.json())
              .then((result) => {
                if (result.url !== undefined) {
                  window.open(`${result.url}`, '_blank')
                  setIsloading(false)
                } else {
                  setIsloading(false)
                  Swal.fire(
                    'Erro na solicitação',
                    'Tivemos um problema na solicitação, tente novamente mais tarde.',
                    'error'
                  )
                }
              })
              .catch((error) => {
                setIsloading(false)
                Swal.fire(
                  'Erro na solicitação',
                  'Tivemos um problema na solicitação, tente novamente mais tarde.',
                  'error'
                )
              })
          })
          .catch((error) => {
            setIsloading(false)
            console.log('error', error)
          })
      } else if (result.isDenied) {
        // Swal.fire('Solicitação cancelada', '', 'info')
      }
    })
  }

  function onChangeParcelas(ev) {
    setqtdparcelas(ev.target.value)
  }

  useEffect(() => {
    // Repasso os dados para o Google Analitcs
    ReactGA.pageview(window.location.pathname + window.location.search)

    setisLoadTurmas(true)
    fetch(`${api}/getlistaturmas?ra=${localStorage.getItem('@meuboleto/ra')}`)
      .then((response) => response.json())
      .then((result) => {
        setisLoadTurmas(false)
        setDadosturmas(result.dados)
        setUser({
          ...user,
          codperlet: result.dados[0].CODPERLET,
        })
        setcodperlet(result.dados[0].CODPERLET)
        setcodturma(result.dados[0].CODTURMA)
        localStorage.setItem('@meuboleto-app/filial', result.dados[0].CODFILIAL)

        getEnotas(result.dados[0].CODCOLIGADA, result.dados[0].CODFILIAL)
          .then((id) => {
            localStorage.setItem('@meuboleto-app/idempresaenotas', id)
          })
          .catch((error) => console.log('error', error))

        // @@@@@@ CARREGO O OS PARAMS DE AGNDAMENTO DE MD @@@@@@@@@

        var myHeaders = new Headers()
        myHeaders.append(
          'Cookie',
          'ci_session=hbhc08tf6qs3lpr8s51g8kd2t3o9qilj'
        )

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }

        fetch(
          `${api}/listaparammaterialagendado?coligada=${localStorage.getItem(
            '@meuboleto-app/coligada'
          )}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            //console.log(result.dados[0]);
            setdadosParamsMd(result.dados[0])
          })
          .catch((error) => console.log('error', error))

        // // @@@@@@ CARREGO O VALOR DA BOLSA @@@@@@@@@
        // var myHeaders = new Headers();
        // myHeaders.append(
        //   "Cookie",
        //   "ci_session=4djo7hh93cdrjvm65v4mosgivkjutsaf"
        // );

        // var requestOptions = {
        //   method: "GET",
        //   headers: myHeaders,
        //   redirect: "follow",
        // };

        // fetch(
        //   `${api}/bolsa?ra=${localStorage.getItem(
        //     "@meuboleto/ra"
        //   )}&coligada=${result.dados[0].CODCOLIGADA}&codturma=${result.dados[0].CODTURMA
        //   }&codperlet=${result.dados[0].CODPERLET}`,
        //   requestOptions
        // )
        //   .then((response) => response.json())
        //   .then((result) => {
        //     setTembolsa(result.record_count);
        //     //setdesconto(result.dados[0].DESCONTO);
        //     //settpdesc(result.dados[0].TIPO_DESC.trim());
        //   })
        //   .catch((error) => console.log("error", error));

        // // @@@@@@ CARREGO O TOTAL DE MENSALIDADES PAGAS @@@@@@@@@
        // var myHeaders = new Headers();
        // myHeaders.append(
        //   "Cookie",
        //   "ci_session=hbvv24nd9ua62qmkm3701acvqj6oi0v6"
        // );

        // var requestOptions = {
        //   method: "GET",
        //   headers: myHeaders,
        //   redirect: "follow",
        // };

        // fetch(
        //   `${api}/totaldemensalidadespagas?ra=${localStorage.getItem(
        //     "@meuboleto/ra"
        //   )}&coligada=${result.dados[0].CODCOLIGADA}&codperlet=${result.dados[0].CODPERLET
        //   }&codturma=${result.dados[0].CODTURMA}`,
        //   requestOptions
        // )
        //   .then((response) => response.json())
        //   .then((result) => {
        //     setTotmensalidadespagas(result.record_count);
        //   })
        //   .catch((error) => console.log("error", error));

        // // @@@@@@ CARREGO O TOTAL DE MENSALIDADES ABERTAS @@@@@@@@@
        // var myHeaders = new Headers();
        // myHeaders.append(
        //   "Cookie",
        //   "ci_session=hbvv24nd9ua62qmkm3701acvqj6oi0v6"
        // );

        // var requestOptions = {
        //   method: "GET",
        //   headers: myHeaders,
        //   redirect: "follow",
        // };

        // fetch(
        //   `${api}/totaldemensalidadesabertas?ra=${localStorage.getItem(
        //     "@meuboleto/ra"
        //   )}&coligada=${result.dados[0].CODCOLIGADA}&codperlet=${result.dados[0].CODPERLET
        //   }&codturma=${result.dados[0].CODTURMA}`,
        //   requestOptions
        // )
        //   .then((response) => response.json())
        //   .then((result) => {
        //     setTotmensalidadesabertas(result.record_count);
        //   })
        //   .catch((error) => console.log("error", error));

        // @@@@@@ CARREGO AS TURMAS POR PERIODO LETIVO @@@@@@

        fetch(
          `${api}/getlistaturmasperlet?ra=${localStorage.getItem(
            '@meuboleto/ra'
          )}&codperlet=${result.dados[0].CODPERLET}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setDadosturmasperlet(result.dados)
            setUser({
              ...user,
              codperlet: result.dados[0].CODPERLET,
            })
          })
          .catch((error) => console.log('error', error))

        listarMensalidades(
          localStorage.getItem('@meuboleto/ra'),
          result.dados[0].CODCOLIGADA,
          result.dados[0].CODPERLET,
          result.dados[0].CODTURMA
        )

        // listarTotaisMensalidadesAbertasPagas(localStorage.getItem("@meuboleto/ra"), result.dados[0].CODCOLIGADA, result.dados[0].CODPERLET, result.dados[0].CODTURMA);

        // @@@@ LISTA O MATERAIL DIDÁTICO  @@@@@@

        // @@@@@@ CARREGO O TOTAL DE MATERIAL @@@@@@@@@
        var myHeaders = new Headers()
        myHeaders.append(
          'Cookie',
          'ci_session=hbvv24nd9ua62qmkm3701acvqj6oi0v6'
        )

        var myHeaders = new Headers()
        myHeaders.append(
          'Cookie',
          'ci_session=rt75or4mao5dkuc736kdl4kj6nc37cmn'
        )

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }

        // fetch(
        //   `${api}/listamaterialh?ra=${localStorage.getItem(
        //     "@meuboleto/ra"
        //   )}&coligada=${result.dados[0].CODCOLIGADA}&codturma=${result.dados[0].CODTURMA
        //   }&codperlet=${result.dados[0].CODPERLET}`,
        //   requestOptions
        // )
        //   .then((response) => response.json())
        //   .then((result) => {
        //     //console.log(result.dados);
        //     setdadosMaterial(result.dados);

        //     const resultado = result.dados.find(rsdados => rsdados.AGENDAMENTOSTATUS === 'PREAGENDADO');

        //     if (resultado !== undefined) {
        //       /* Exibe o popup de MD Agendado */
        //       var myHeaders = new Headers();
        //       myHeaders.append(
        //         "Cookie",
        //         "ci_session=ppvr57m0uuj42g8t10f947i2tf7j4jup"
        //       );

        //       var requestOptions = {
        //         method: "GET",
        //         headers: myHeaders,
        //         redirect: "follow",
        //       };

        //       fetch(
        //         `${api}/listamaterialagendado?ra=${localStorage.getItem(
        //           "@meuboleto/ra"
        //         )}&coligada=${localStorage.getItem(
        //           "@meuboleto-app/editora"
        //         )}&codturma=${localStorage.getItem(
        //           "@meuboleto/turma"
        //         )}&codperlet=${localStorage.getItem("@meuboleto/perlet")}`,
        //         requestOptions
        //       )
        //         .then((response) => response.json())
        //         .then((result) => {
        //           setIsloadParcAgenda(false);
        //           setDadosagendados(result.dados);
        //           setModalagendar(!modalagendar);
        //         })
        //         .catch((error) => {
        //           setIsloadParcAgenda(false);
        //           console.log("error", error)
        //         });
        //     }
        //   })
        //   .catch((error) => {
        //     setdadosMaterial([]);
        //     console.log("error", error);
        //   });
      })
      .catch((error) => console.log('error', error))

    // fetchData(user.codcoligada, user.codfilial).then(style => {
    //   setGradientStyle(style);

    //   const { background } = style;
    //   document.body.style.background = background;
    // });

    const { background } = gradientStyle
    document.body.style.background = background

    return () => {
      document.body.classList.remove('bg-default')
      document.body.style.background = '' // Limpa a cor quando o componente é desmontado
    }
  }, [])

  function onChange(ev) {
    var resultado = ev.target.value.split('@')
    setUser({
      ...user,
      codperlet: resultado[0],
    })
    localStorage.setItem('@meuboleto/perlet', resultado[0]) // codperlet

    setTotmensalidades(<Skeleton height={20} />)
    setTotmensalidadespagas(<Skeleton height={20} />)
    setTotmensalidadesabertas(<Skeleton height={20} />)
    setTotmmaterial(<Skeleton height={20} />)
    setTotmaterialpagas(<Skeleton height={20} />)
    setTotmaterialabertas(<Skeleton height={20} />)

    var myHeaders = new Headers()
    myHeaders.append('Cookie', 'ci_session=h6jqsvm0pc9h7rca5k49u0gesdfs3b0b')

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(
      `${api}/listamensalidadesra?ra=${localStorage.getItem(
        '@meuboleto/ra'
      )}&codperlet=${resultado[0]}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        localStorage.setItem(
          '@meuboleto/rsmensalidades',
          JSON.stringify(result.dados)
        )
        setdadosMensalidades(result.dados)
        setTotmensalidades(result.record_count)
      })
      .catch((error) => console.log('error', error))
  }

  function onSubmitContext(e) {
    e.preventDefault()
    //console.log(e);
  }

  return (
    <>
      {/* <Header /> */}
      <div style={gradientStyle}>
        <Container style={{ paddingTop: '60px' }} fluid>
          <span style={{ color: '#fff' }}>
            RA: {localStorage.getItem('@meuboleto/ra')}
          </span>
          <br />
          <span style={{ color: '#fff' }}>
            Aluno(a): {localStorage.getItem('@meuboleto/nome')}
          </span>
          <br />
          <br />
          <br />
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="6" style={{ color: '#fff' }}>
                <form onSubmit={onSubmitContext}>
                  <div className="form-group">
                    <label>Escolha o ano letivo:</label>
                    <select
                      id="codturma"
                      name="codturma"
                      className="form-control input-shadow"
                      onChange={onChange}
                      required
                    >
                      {isLoadTurmas ? (
                        <option>Carregando...</option>
                      ) : (
                        dadosturmas.map((itemTurma) => (
                          <option
                            key={itemTurma.CODTURMA}
                            value={
                              itemTurma.CODPERLET + '@' + itemTurma.CODCOLIGADA
                            }
                          >
                            Período letivo de {itemTurma.CODPERLET}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      {/* FIM DO HEADER */}

      {/*   MODAL (AGENDAR / PAGAR)  */}

      <Modal centered isOpen={modalagendamento} toggle={abriropcao}>
        <ModalHeader toggle={abriropcao}>Pagamento / Agendamento </ModalHeader>
        <ModalBody>
          <Col lg="12" xl="12" style={{ color: '#fff' }}>
            <>
              {mdstatusagendamento === 'PREAGENDADO' ? null : (
                <Button
                  color={primaryColor}
                  style={{ backgroundColor: primaryColor, color: '#fff' }}
                  onClick={() => {
                    /* ORIGINAL */
                    setModal(!modal)
                  }}
                >
                  Pagar com cartão
                </Button>
              )}

              <Button
                className={
                  mdstatusagendamento === 'PREAGENDADO' ? 'btn btn-block' : null
                }
                color={primaryColor}
                style={{ backgroundColor: primaryColor, color: '#fff' }}
                onClick={() => {
                  /* ORIGINAL */
                  setModalagendar(!modalagendar)
                  setIsloadParcAgenda(true)

                  var myHeaders = new Headers()
                  myHeaders.append(
                    'Cookie',
                    'ci_session=ppvr57m0uuj42g8t10f947i2tf7j4jup'
                  )

                  var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow',
                  }

                  fetch(
                    `${api}/listamaterialagendado?ra=${localStorage.getItem(
                      '@meuboleto/ra'
                    )}&coligada=${localStorage.getItem(
                      '@meuboleto-app/editora'
                    )}&codturma=${localStorage.getItem(
                      '@meuboleto/turma'
                    )}&codperlet=${localStorage.getItem('@meuboleto/perlet')}`,
                    requestOptions
                  )
                    .then((response) => response.json())
                    .then((result) => {
                      setIsloadParcAgenda(false)
                      setDadosagendados(result.dados)
                    })
                    .catch((error) => {
                      setIsloadParcAgenda(false)
                      console.log('error', error)
                    })
                }}
              >
                Agendar Pagamento
              </Button>
            </>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={abriropcao}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      {/*   FIM DO MODAL (AGENDAR / PAGAR)  */}

      {/*   MODAL AGENDAR  */}

      <Modal centered isOpen={modalagendar} toggle={abriragendamento}>
        <ModalHeader toggle={abriragendamento} style={{ paddingBottom: '0px' }}>
          Agendamento de pagamento recorrente
        </ModalHeader>
        <ModalBody>
          <Col lg="12" xl="12" style={{ color: '#fff' }}>
            <span
              style={{
                color: '#141414',
                fontWeight: '200',
                fontSize: '14px',
                marginTop: '-15px',
              }}
            >
              Você optou por agendar os pagamentos listados abaixo. Por favor,
              clique em "Agendar Pagamento" para concluir o processo de
              agendamento em seu Cartão de Crédito.
            </span>
            <br />
            <br />
            <p>
              <span style={{ color: '#141414' }}>
                Boletos a serem agendados:
              </span>
            </p>
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Boleto</th>
                  <th scope="col">Vencimento</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                {isloadparcagenda ? (
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'center' }}>
                      Carregando...
                    </td>
                  </tr>
                ) : dadosagendados.length > 0 ? (
                  dadosagendados.map((itemParc, Index) => (
                    <tr key={itemParc.IDBOLETO}>
                      <td>{itemParc.IDBOLETO}</td>
                      <td>{itemParc.VENCIMENTO}</td>
                      <td>{itemParc.VALORLIQ}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'center' }}>
                      Não existem boletos a serem agendados.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
        </ModalBody>
        <ModalFooter>
          {dadosagendados.length > 0 ? (
            <Button
              color={primaryColor}
              style={{ backgroundColor: primaryColor, color: '#fff' }}
              onClick={onSubmitAgendamento}
            >
              {isloading ? (
                <>
                  <SpinnerCircular
                    color="rgba(255, 255, 255, 1)"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                    size={20}
                  />
                  <span>Aguarde...</span>
                </>
              ) : (
                'Agendar Pagamento'
              )}
            </Button>
          ) : (
            ''
          )}

          <Button color="secondary" onClick={abriragendamento}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      {/* MODAL LISTA SERVIÇOS DO BOLETO */}

      <Modal centered isOpen={modal2} toggle={abrir}>
        <ModalHeader toggle={abrir}>
          <h3>Lista de Serviços do Boleto </h3>
        </ModalHeader>
        <ModalBody>
          {servicos.length > 0 ? (
            servicos.map((itemParc, Index) => (
              <>
                <span style={{ color: '#000' }}>- {itemParc.NOME}</span>
                <br />
              </>
            ))
          ) : (
            <span style={{ color: '#000' }}>
              Não existem boletos a serem agendadas.
            </span>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={abrir}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      {/*   FIM DO MODAL AGENDAR  */}

      {/* MODAL PARCELAMENTO */}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Pagamento via cartão</ModalHeader>
        <ModalBody>
          <Col lg="12" xl="12" style={{ color: '#fff' }}>
            <form>
              <div className="form-group">
                <label style={{ color: '#000' }}>
                  Escolha o número de parcelas:
                </label>
                <select
                  id="parcelas"
                  name="v"
                  className="form-control input-shadow"
                  onChange={onChangeParcelas}
                  required
                >
                  <option>Selecione a qtd. de parcelas</option>
                  {/* {qtdparcelascartao.map(opcao => (
                    <option value={opcao}>{opcao}x</option>
                  ))} */}
                  {isloadqtdparc ? (
                    <option value={0}>Buscando...</option>
                  ) : (
                    <option value={qtdparcelascartao.QtdParcelas}>
                      {qtdparcelascartao.QtdParcelas}x
                    </option>
                  )}
                </select>
              </div>
            </form>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button
            color={primaryColor}
            style={{ backgroundColor: primaryColor, color: '#fff' }}
            onClick={onSubmitParcelas}
            disabled={isloadqtdparc ? true : false}
          >
            Pagar com Cartão
          </Button>
          <Button color="secondary" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Container fluid style={gradientStyle}>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow" style={{ paddingBottom: '10px' }}>
              <CardHeader className="border-0">
                <h3 className="mb-0">Período letívo - {user.codperlet}</h3>
              </CardHeader>

              {isload ? (
                <Carregando />
              ) : dadosmensalidades.length ? (
                renderizarItensPorTurma(dadosmensalidades)
              ) : (
                <CardSemDados />
              )}
            </Card>
          </div>
        </Row>

        <div style={{ height: '40px' }}></div>
      </Container>
    </>
  )
}

export default Tables
