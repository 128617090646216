import React, { createContext, useContext, useState } from "react";

export const AppContext = createContext({});

export const AuthProvider = (props) => {
  const [user, setUser] = useState({
    codcoligada: "12",
    codfilial: "1",
    codeditora: "16",
    codeditorafilial: "1",
    codperlet: "2023",
    idempresaenotas: "",
    idboleto: "",
    codturma: "",
    ra: "",
    nome: "",
    logado: false,
    logo: "",
    app: "",
    bgcolor: "#003F6E",
  });
  
  return (
    <AppContext.Provider value={{ user, setUser }}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useAuth = () => useContext(AppContext);