import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Lottie from 'lottie-react';
import { lighten } from 'polished';
import ReactGA from "react-ga4";
import { AuthProvider, useAuth } from "../../providers/auth";
import classnames from 'classnames';
import animationData from '../../assets/lottiefiles/carregandodeclaracoes.json';

const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID;
ReactGA.initialize(googleAnalitcsID);

const api = process.env.REACT_APP_HOST;

const DeclaracaoIR = () => {
  const { user, setUser } = useAuth();
  const [dadosturmas, setDadosturmas] = useState([]);
  const [codperlet, setcodperlet] = useState(localStorage.getItem("@meuboleto/perlet"));
  const [urlframe, seturlframe] = useState("");
  const [isLoadTurmas, setisLoadTurmas] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  // Identidade da Marca
  const primaryColor = localStorage.getItem("@meuboleto/bgcolor") ? localStorage.getItem("@meuboleto/bgcolor") : '#1A508C';
  const lighterPrimaryColor = lighten(0.2, primaryColor);
  const [gradientStyle, setGradientStyle] = useState({
    // background: `linear-gradient(to right, ${primaryColor}, ${lighterPrimaryColor})`,
    background: 'linear-gradient(to right, #fafafa, #f5f5f5)',
    width: '100%'
  });

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  useEffect(() => {
    setisLoadTurmas(true);

    fetch(`${api}/getlistaturmas?ra=${localStorage.getItem("@meuboleto/ra")}`)
      .then(response => response.json())
      .then(result => {
        setisLoadTurmas(false);
        setDadosturmas(result.dados);
        setSelectedOption(result.dados[0].CODTURMA + '@' + result.dados[0].CODPERLET);

        var payLoad = JSON.stringify({
          "ra": localStorage.getItem("@meuboleto/ra"),
          "coligada": localStorage.getItem("@meuboleto-app/coligada"),
          "codturma": result.dados[0].CODTURMA,
          "codperlet": result.dados[0].CODPERLET
        });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "ci_session=f3sr0hcvbthkcbtp3qnuki8u0qhtpas3");

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: payLoad,
          redirect: 'follow'
        };

        fetch(`${api}/declaracaoirrftoken`, requestOptions)
          .then(response => response.json())
          .then(result => {
            seturlframe(`${api}/DeclaracaoIrrfDev?data=${result.hash}`)
          })
          .catch(error => console.log('error', error));
      })
      .catch(error => {
        console.log('error', error);
      });

    const { background } = gradientStyle;
    document.body.style.background = background;

    return () => {
      document.body.classList.remove("bg-default");
      document.body.style.background = ''; // Limpa a cor quando o componente é desmontado
    };
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setIframeLoaded(false);
      var resultado = selectedOption.split("@");
      setcodperlet(resultado[1]);
      var payLoad = JSON.stringify({
        "ra": localStorage.getItem("@meuboleto/ra"),
        "coligada": localStorage.getItem("@meuboleto-app/coligada"),
        "codturma": resultado[0],
        "codperlet": resultado[1]
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=f3sr0hcvbthkcbtp3qnuki8u0qhtpas3");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: payLoad,
        redirect: 'follow'
      };

      fetch(`${api}/declaracaoirrftoken`, requestOptions)
        .then(response => response.json())
        .then(result => {
          seturlframe(`${api}/DeclaracaoIrrfDev?data=${result.hash}`)
        })
        .catch(error => console.log('error', error));
    }
  }, [selectedOption]);

  function onChange(ev) {
    setSelectedOption(ev.target.value);
  }

  return (
    <>
      {/* <div className={`header bg-gradient-${localStorage.getItem("@meuboleto-app/bgcolor")} pb-8 pt-5 pt-md-8`}> */}
      <div
        className={`header pb-8 pt-5 pt-md-8`}
        style={gradientStyle}
      >
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="4" style={{ color: '#fff' }}>
                <form>
                  <div className="form-group">
                    <select
                      id='codturma'
                      name="codturma"
                      className="form-control input-shadow"
                      onChange={onChange}
                      style={{ height: '30px', paddingTop : '0.25rem', paddingBottom : '0.25rem' }} 
                      value={selectedOption}
                      required
                    >
                      {
                        isLoadTurmas ? <option >Carregando...</option> :
                          dadosturmas.length > 0 ?
                            dadosturmas.map(itemTurma => (
                              <option key={itemTurma.CODTURMA} value={itemTurma.CODTURMA + '@' + itemTurma.CODPERLET}>Período letivo de {itemTurma.CODPERLET}</option>
                            ))
                            : <option >Não existe declaração disponível...</option>
                      }
                    </select>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container 
        className={`header mt--8`} 
        style={gradientStyle}
        fluid
      >
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Declaração por data de Recebimento - {codperlet}</h3>
              </CardHeader>
              <CardBody>
                {iframeLoaded ?
                  null
                  :
                  (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                      <Lottie
                        animationData={animationData}
                        style={{
                          width: 300,
                          height: 300,
                        }}
                      />
                    </div>
                  )
                }
                <iframe
                  src={urlframe}
                  width={'100%'}
                  height={'780px'}
                  style={{ display: iframeLoaded ? 'block' : 'none' }}
                  onLoad={handleIframeLoad}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DeclaracaoIR;
